import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import TopBar from "../other-components/TopBar";
import ToastDialog from "../dialogs/ToastDialog/toast";
import { API_ACCESS_URL, getURLParam } from "../modals/Constants";
import { getStorage } from "../modals/Storage";
import LoadingDialog from "../dialogs/LoadingDialog/dialog";

import styles from "../css/Screens.module.css";
import general from "../css/General.module.css";

function AddBankCard() {
  const navigate = useNavigate();
  const toastDialogRef = useRef(null);
  const [isInputValCorrect, setInValCorrect] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  const [pageConst, setConstants] = useState({
    pageTitle: "Add Bank Card",
    inAccountNum: "",
    inBeneficiary: "",
    inIFSCCode: "AAAA061243",
    inBankName: "",
    inBranchName: "",
    inPrimaryOption: "true",
    bankCardMethod: "",
    tabActiveReord: "bank",
    bankList: [],
    recordList: [],
  });

  useEffect(() => {
    const handleError = (event: ErrorEvent) => {
      console.error('Unhandled error:', event.error);
      showToast(`An unexpected error occurred: ${event.error.message}`, 3000, 0, "error");
    };

    window.addEventListener('error', handleError);

    return () => {
      window.removeEventListener('error', handleError);
    };
  }, []);

  const topBarClickAction = (data) => {
    // Implementation here
  };

  const updateActiveTab = (data) => {
    if (data == "upi") {
      //setInBankCorrect(true);
    } else {
      //setInBankCorrect(false);
    }
    setConstants((previousState) => {
      return { ...previousState, tabActiveReord: data };
    });
  };

  const updateLoadingStatus = (data) => {
    setShowLoading(data);
  };

  const onToastChange = (data, msg) => {
    // Implementation here
  };

  const showToast = (message: string, duration: number = 3000, delay: number = 0, mode: 'success' | 'error' | 'info' = 'info') => {
    if (toastDialogRef.current) {
      toastDialogRef.current.openDialog(message, duration, delay, mode);
    }
  };

  const checkForInputVal = (beneficiary: string, accountNum: string) => {
    setInValCorrect(beneficiary.length > 3 && accountNum.length > 5);
  };

  const onInputValChange = (source: string, data: string) => {
    if (source === "beneficiary") {
      checkForInputVal(data, pageConst.inAccountNum);
      setConstants((prevState) => ({ ...prevState, inBeneficiary: data }));
    } else if (source === "accountNum") {
      checkForInputVal(pageConst.inBeneficiary, data);
      setConstants((prevState) => ({ ...prevState, inAccountNum: data }));
    } else if (source === "bankName") {
      setConstants((prevState) => ({ ...prevState, inBankName: data }));
    }
  };

  const setPrimaryOption = (data) => {
    setConstants((previousState) => {
      return { ...previousState, inPrimaryOption: data };
    });
  };

  const validateInputs = () => {
    const errors = [];
    if (pageConst.inBeneficiary.length <= 3) {
      errors.push('Beneficiary name must be longer than 3 characters');
    }
    if (pageConst.inAccountNum.length <= 5) {
      errors.push('Account number must be longer than 5 characters');
    }
    if (pageConst.tabActiveReord === 'bank' && !pageConst.inBankName) {
      errors.push('Bank name is required for bank accounts');
    }
    return errors;
  };

  const addNewBankCard = () => {
    const errors = validateInputs();
    if (errors.length > 0) {
      showToast(`Please correct the following: ${errors.join(', ')}`, 5000, 0, "error");
      return;
    }

    const fetchApiData = async (url: string) => {
      try {
        updateLoadingStatus(true);
        console.log('Sending request to:', url);
        const res = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Route: "route-add-bankcard",
            AuthToken: getStorage("secret"),
          },
        });

        console.log('Response status:', res.status);
        const responseText = await res.text();
        console.log('Response text:', responseText);

        if (!res.ok) {
          throw new Error(`HTTP error! status: ${res.status}, body: ${responseText}`);
        }

        let data;
        try {
          data = JSON.parse(responseText);
        } catch (e) {
          console.error('Error parsing JSON:', e);
          throw new Error('Invalid JSON response from server');
        }

        console.log('Parsed response data:', data);

        if (data.status_code === "success") {
          showToast("Bank card added successfully!", 3000, 0, "success");
          setTimeout(() => navigate(-1), 3000);
        } else if (data.status_code === "already_exist") {
          showToast("Account Number already exists! Please try a different one.", 3000, 0, "error");
        } else if (data.status_code === "limit_reached") {
          showToast("You've reached the maximum number of bank cards allowed.", 3000, 0, "error");
        } else if (data.status_code === "invalid_params") {
          console.error('Invalid parameters:', data.invalid_params);
          const invalidParamsMessage = data.invalid_params 
            ? `Invalid parameters: ${data.invalid_params.join(', ')}`
            : 'Some parameters are invalid';
          showToast(`${invalidParamsMessage}. Please check your inputs and try again.`, 5000, 0, "error");
        } else {
          console.error('Unexpected status code:', data.status_code);
          showToast(`An unexpected error occurred (${data.status_code}). Please try again.`, 3000, 0, "error");
        }
      } catch (error) {
        console.error("Error adding bank card:", error);
        showToast(`Network error: ${error.message}. Please check your connection and try again.`, 3000, 0, "error");
      } finally {
        updateLoadingStatus(false);
      }
    };

    const url = `${API_ACCESS_URL}?USER_ID=${encodeURIComponent(getStorage("uid"))}&BENEFICIARY_NAME=${encodeURIComponent(pageConst.inBeneficiary)}&USER_BANK_NAME=${encodeURIComponent(pageConst.inBankName)}&USER_BANK_ACCOUNT=${encodeURIComponent(pageConst.inAccountNum)}&USER_BANK_IFSC_CODE=AAAA061243&IS_PRIMARY=${encodeURIComponent(pageConst.inPrimaryOption)}&CARD_METHOD=${encodeURIComponent(pageConst.tabActiveReord)}`;
    fetchApiData(url);
  };

  const getBankList = () => {
    const fetchApiData = async (url) => {
      try {
        const res = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Route: "route-get-banklist",
            AuthToken: getStorage("secret"),
          },
        });

        const data = await res.json();
        updateLoadingStatus(false);

        setConstants((previousState) => {
          return { ...previousState, bankList: data.data.banklist };
        });
      } catch (error) {
        updateLoadingStatus(false);
      }
    };

    updateLoadingStatus(true);
    fetchApiData(API_ACCESS_URL);
  };

  return (
    <div className={`${general.viewCenter} ${general.appBackground}`}>
      <div
        className={`${general.height100vh} ${general.respWidth} ${general.overflowScrollY} ${general.appContentsBackground} ${general.hideScrollBar}`}
      >
        <TopBar
          intentData={pageConst}
          multiBtn={false}
          multiBtn1=""
          multiBtn2=""
          updateState={topBarClickAction}
        />
        <ToastDialog onToastChange={onToastChange} ref={toastDialogRef} />

        <LoadingDialog intentData={showLoading} />

        <div className={general.zoomAnimView}>
          <div className={`${general.colView} ${general.mgT50px}`}>
            <div className={`${general.width100} ${general.rowView} ${general.pd10px}`}>
              <div
                className={`${general.width100} ${general.viewCenter} ${general.colorWhite} ${general.pd10px} ${general.fontS16Px} ${general.borderRadius} ${pageConst.tabActiveReord == "upi" ? general.bgCustomColor2 : general.bgCustomColor4}`}
                onClick={() => updateActiveTab("upi")}
              >
                GOPAY
              </div>
              <div
                className={`${general.width100} ${general.viewCenter} ${general.colorWhite} ${general.pd10px} ${general.fontS16Px} ${general.borderRadius} ${general.mgL5px} ${pageConst.tabActiveReord == "bank" ? general.bgCustomColor2 : general.bgCustomColor4}`}
                onClick={() => updateActiveTab("bank")}
              >
                BANK
              </div>
            </div>

            {/* Bank Tab Content */}
            <div className={`${general.colView} ${general.pd10px15px} ${general.mgT15px} ${pageConst.tabActiveReord == "upi" ? general.hideView : ""}`}>
              {/* Beneficiary Name Input */}
              <div className={`${styles.inputView} ${general.colView} ${general.width100}`}>
                <span className={`${general.fontS13Px} ${general.colorLightWhite}`}>
                  Beneficiary Name:
                </span>
                <input
                  type="text"
                  className={`${general.inputBox} ${general.height50px} ${general.mgT10px} ${general.borderRadius10px} ${general.bgCustomColor6}`}
                  placeholder="Please enter full name"
                  autoComplete="new-password"
                  onChange={(e) => onInputValChange("beneficiary", e.target.value)}
                />
              </div>

              {/* Account Number Input */}
              <div className={`${styles.inputView} ${general.colView} ${general.width100} ${general.mgT30px}`}>
                <span className={`${general.fontS13Px} ${general.colorLightWhite}`}>
                  Account Number:
                </span>
                <input
                  type="text"
                  className={`${general.inputBox} ${general.height50px} ${general.mgT10px} ${general.borderRadius10px} ${general.bgCustomColor6}`}
                  placeholder="Please enter account number"
                  autoComplete="new-password"
                  onChange={(e) => onInputValChange("accountNum", e.target.value)}
                />
              </div>

              {/* Bank Name Input */}
              <div className={`${styles.inputView} ${general.colView} ${general.width100} ${general.mgT30px}`}>
                <span className={`${general.fontS13Px} ${general.colorLightWhite}`}>
                  Bank Name:
                </span>
                <input
                  type="text"
                  className={`${general.inputBox} ${general.height50px} ${general.mgT10px} ${general.borderRadius10px} ${general.bgCustomColor6}`}
                  placeholder="Please enter bank name"
                  autoComplete="new-password"
                  onChange={(e) => onInputValChange("bankName", e.target.value)}
                  value={pageConst.inBankName}
                />
              </div>

              {/* Bank Name Display */}
              <div className={`${general.colView} ${general.mgT10px} ${pageConst.inBankName == "" ? general.hideView : ""}`}>
                <p className={`${general.fontS13Px} ${general.colorLightWhite}`}>
                  Bank Name: {pageConst.inBankName}
                </p>
              </div>

              {/* Save Button */}
              <div
                className={`${general.height50px} ${general.width100} ${general.viewCenter} ${general.colorWhite} ${general.fontS20Px} ${general.mgT30px} ${general.borderRadius} ${isInputValCorrect ? general.bgCustomColor2 : general.bgDisable}`}
                onClick={addNewBankCard}
              >
                Save
              </div>
            </div>

            {/* GOPAY Tab Content */}
            <div className={`${general.colView} ${general.pd10px15px} ${general.mgT15px} ${pageConst.tabActiveReord == "bank" ? general.hideView : ""}`}>
              {/* Beneficiary Name Input */}
              <div className={`${styles.inputView} ${general.colView} ${general.width100}`}>
                <span className={`${general.fontS13Px} ${general.colorLightWhite}`}>
                  Beneficiary Name:
                </span>
                <input
                  type="text"
                  className={`${general.inputBox} ${general.height50px} ${general.mgT10px} ${general.borderRadius10px} ${general.bgCustomColor6}`}
                  placeholder="Please enter full name"
                  autoComplete="new-password"
                  onChange={(e) => onInputValChange("beneficiary", e.target.value)}
                />
              </div>

              {/* GOPAY Input */}
              <div className={`${styles.inputView} ${general.colView} ${general.width100} ${general.mgT30px}`}>
                <span className={`${general.fontS13Px} ${general.colorLightWhite}`}>
                  GOPAY :
                </span>
                <input
                  type="text"
                  className={`${general.inputBox} ${general.height50px} ${general.mgT10px} ${general.borderRadius10px} ${general.bgCustomColor6}`}
                  placeholder="Please enter GOPAY"
                  autoComplete="new-password"
                  onChange={(e) => onInputValChange("accountNum", e.target.value)}
                />
              </div>

              {/* Save Button */}
              <div
                className={`${general.height50px} ${general.width100} ${general.viewCenter} ${general.colorWhite} ${general.fontS20Px} ${general.mgT30px} ${general.borderRadius} ${isInputValCorrect ? general.bgCustomColor2 : general.bgDisable}`}
                onClick={addNewBankCard}
              >
                Save
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddBankCard;

