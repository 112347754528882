import React from 'react'
import '../../MainStyle.css';

const fastParityRuleDialog = ({intentData,updateState}) => {

    return (
        <div className={`ps-fx h-100vh res-wth z-i--100 bg-l-black rul-dlg ${intentData.ruleDialogShow ? 'activeDialog' : ''}`} onClick={(e)=>{updateState(e,false)}}>
            <div className='dlg-c ps-fx ps-btm col-view a-center bg-l-grey res-wth h-450-p pd-15 ovf-scrl hide-sb'>
                <p className='ft-sz-18 mg-b-10 cl-white pd-5-15 br-20 bx-shdw-blue bg-blue'>Andar Bahar Rule</p>

                <div className='col-view pd-5 mg-b-50 w-100'>
                    <div className='ft-sz-16 cl-grey'>
                    Andar Bahar is a guessing game, you can choose Andar, Bahar, or Tie.
                    </div>

                    <div className='ft-sz-16 mg-t-10 cl-grey'>
                    50 seconds to order, after the order time, Show a hole card first. Then, in the order of Andar → Bahar, the cards are dealt in turn, and the card with the same number of points as the hole card is dealt first, and which side wins.
                    </div>

                    <div className='ft-sz-16 mg-t-10 cl-grey'>
                    If the hole card shows a 2, then Andar and Bahar, whoever shows the 2 first, wins. If no 2 is shown in 8 consecutive cards, it is a tie.
                    </div>

                    <div className='ft-sz-16 mg-t-10 cl-grey'>
                    If you spend Rp100 to trade, after deducting 2 Rupiahs service fee, your contract amount is 98 Rupiahs:
                    </div>

                </div>

                <div className='pd-10 ps-fx ps-btm res-wth bg-l-grey'>
                  <div className='bg-blue h-45-p ft-sz-15 pd-10 br-10 cl-white v-center w-100' onClick={(e)=>{updateState(e,"dismiss")}}>
                    <span>I GOT IT</span>
                  </div>
                </div>
                
            </div>
        </div>
    );
};

export default fastParityRuleDialog;