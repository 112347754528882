import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import TopBar from "../other-components/TopBar";
import ToastDialog from "../dialogs/ToastDialog/toast";
import "../../MainStyle.css";
import { API_ACCESS_URL, redirectTo } from "../modals/Constants";
import { getStorage } from "../modals/Storage";
import LoadingDialog from "../dialogs/LoadingDialog/dialog";

function DailyCheckIn() {
  const navigate = useNavigate();
  const toastDialogRef = useRef(null);
  const [showLoading, setShowLoading] = useState(false);

  const [pageConst, setConstants] = useState({
    pageTitle: "Check In",
    checkInDays: 0,
  });

  const updateLoadingStatus = (data) => {
    setShowLoading(data);
  };

  // toast component
  const onToastChange = (data, msg) => { };

  const showToast = (message, duration, delay, mode) => {
    toastDialogRef.current.openDialog(message, duration, delay, mode);
  };

  const claimDailyCheckIn = () => {
    const requestAPI = async (url) => {
      try {
        const res = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Route: "route-claim-checkin",
            AuthToken: getStorage("secret"),
          },
        });

        const data = await res.json();
        updateLoadingStatus(false);

        if (data.status_code == "authorization_error") {
          showToast("Authorization Error!");
        } else if (data.status_code == "no_premium") {
          showToast(
            "Sorry! Please complete your first recharge!"
          );
        } else if (data.status_code == "already_claimed") {
          showToast("Sorry! You've already Claimed!");
        } else if (data.status_code == "success") {
          showToast("Bonus Claimed!", 2500, 0, 1);
        } else if (data.status_code == "not_available") {
          showToast("Bonus Not Available!");
        } else {
          showToast(
            "Something went wrong! Please try again!"
          );
        }
      } catch (error) {
        updateLoadingStatus(false);
        showToast(
          "There was a technical issue! Please try again!"
        );
      }
    };

    if (showLoading == false) {
      updateLoadingStatus(true);
      requestAPI(API_ACCESS_URL + "?USER_ID=" + getStorage("uid"));
    }
  };

  const getCheckInDays = () => {
    const requestAPI = async (url) => {
      try {
        const res = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Route: "route-load-checkin",
            AuthToken: getStorage("secret"),
          },
        });

        const data = await res.json();
        updateLoadingStatus(false);

        if (data.status_code == "success") {
          setConstants((previousState) => {
            return { ...previousState, checkInDays: data.checkin_days };
          });
        }
      } catch (error) {
        updateLoadingStatus(false);
        showToast(
          "There was a technical issue! Please try again!"
        );
      }
    };

    if (showLoading == false) {
      updateLoadingStatus(true);
      requestAPI(API_ACCESS_URL + "?USER_ID=" + getStorage("uid"));
    }
  };

  useEffect(() => {
    if (!getStorage("uid")) {
      navigate("/login", { replace: true });
    } else {
      getCheckInDays();
    }
  }, []);

  return (
    <div className="v-center bg-black ovf-hidden">
      <div className="h-100vh res-wth app-bg">
        <ToastDialog onToastChange={onToastChange} ref={toastDialogRef} />
        <LoadingDialog intentData={showLoading} />

        <div className="pr-v ovf-scrl-y hide-sb zoomAnimView">
          <TopBar
            intentData={pageConst}
            multiBtn={true}
            multiBtn1=""
            multiBtn2=""
          />

          <div className="col-view w-100 v-center pd-5-15 mg-b-15 mg-t-45">
            <div className="w-100 ps-rl pd-15-0 mg-t-20 br-5 bg-white">
              <div className="w-100 g-v-4">
                <div
                  className={`col-view a-center pd-15-0 ${pageConst.checkInDays >= 1000 ? "greys-100" : ""
                    }`}
                >
                  <span className="ft-sz-17">Day 1</span>
                  <img
                    className="h-40-p mg-t-5"
                    src={require("../icons/coin_check_icon.png")}
                  />
                  <span className="cl-grey ft-sz-23 mg-t-5">+1000</span>
                </div>

                <div
                  className={`col-view a-center pd-15-0 ${pageConst.checkInDays >= 2000 ? "greys-100" : ""
                    }`}
                >
                  <span className="ft-sz-17">Day 2</span>
                  <img
                    className="h-40-p mg-t-5"
                    src={require("../icons/coin_check_icon.png")}
                  />
                  <span className="cl-grey ft-sz-23 mg-t-5">+2000</span>
                </div>

                <div
                  className={`col-view a-center pd-15-0 ${pageConst.checkInDays >= 3000 ? "greys-100" : ""
                    }`}
                >
                  <span className="ft-sz-17">Day 3</span>
                  <img
                    className="h-40-p mg-t-5"
                    src={require("../icons/coin_check_icon.png")}
                  />
                  <span className="cl-grey ft-sz-23 mg-t-5">+3000</span>
                </div>

                <div
                  className={`col-view a-center pd-15-0 ${pageConst.checkInDays >= 4000 ? "greys-100" : ""
                    }`}
                >
                  <span className="ft-sz-17">Day 4</span>
                  <img
                    className="h-40-p mg-t-5"
                    src={require("../icons/coin_check_icon.png")}
                  />
                  <span className="cl-grey ft-sz-23 mg-t-5">+4000</span>
                </div>

                <div
                  className={`col-view a-center pd-15-0 ${pageConst.checkInDays >= 5000 ? "greys-100" : ""
                    }`}
                >
                  <span className="ft-sz-17">Day 5</span>
                  <img
                    className="h-40-p mg-t-5"
                    src={require("../icons/coin_check_icon.png")}
                  />
                  <span className="cl-grey ft-sz-23 mg-t-5">+5000</span>
                </div>

                <div
                  className={`col-view a-center pd-15-0 ${pageConst.checkInDays >= 6000 ? "greys-100" : ""
                    }`}
                >
                  <span className="ft-sz-17">Day 6</span>
                  <img
                    className="h-40-p mg-t-5"
                    src={require("../icons/coin_check_icon.png")}
                  />
                  <span className="cl-grey ft-sz-23 mg-t-5">+6000</span>
                </div>

                <div
                  className={`col-view a-center pd-15-0 ${pageConst.checkInDays >= 7000 ? "greys-100" : ""
                    }`}
                >
                  <span className="ft-sz-17">Day 7</span>
                  <img
                    className="h-40-p mg-t-5"
                    src={require("../icons/coin_check_icon.png")}
                  />
                  <span className="cl-grey ft-sz-23 mg-t-5">+7000</span>
                </div>

                <div className="col-view v-center pd-15-0">
                  <img
                    className="h-70-p"
                    src={require("../icons/treasure_icon.png")}
                  />
                </div>
              </div>

              <div
                className="ps-ab ps-lf-50 ft-sz-20 pd-5-15 cl-white t-form-x br-20 bg-red-grad-2"
                onClick={() => claimDailyCheckIn()}
              >
                Check In
              </div>
            </div>

            <div className="col-view a-center pd-15-0 mg-t-20 mg-b-70">
              <span className="txt-a-center cl-grey ft-sz-17">
                Check in for 7 consecutive days to get treasure box, and receive
                mysterious prizes! .
              </span>
              <img
                className="h-250-p mg-t-5"
                src={require("../icons/treasure_2_icon.png")}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DailyCheckIn;
