import React, { useCallback, useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom';
import TopBar from '../../other-components/TopBar';
import LoadingDialog from '../../dialogs/LoadingDialog';
import ToastDialog from '../../dialogs/ToastDialog/toast';
import WinDialog from '../../dialogs/WinDialog';
import RuleDialog from '../../dialogs/AviatorRuleDialog';
import '../../../MainStyle.css';
import { getStorage, setStorage } from '../../modals/Storage';
import { API_ACCESS_URL } from '../../modals/Constants';

function Aviator() {
  const toastDialogRef = useRef(null);
  const [remainingSec, setCount] = useState(-1);

  const [planeMaxDistance, setMaxDistance] = useState(0);
  const [gamePlayTime, setGamePlayTime] = useState(0);
  const [gamewaitingTime, setWaitingTime] = useState(0);
  const [isGamePlaying, setGamePlaying] = useState(false);
  const [isEntryOptionAvailable, setEntryOption] = useState("");
  const [isWaitingForPlayers, setWaitingForPlayers] = useState(false);

  const [pathCurve1, setPathCurve1] = useState(300);
  const [pathCurve2, setPathCurve2] = useState(50);
  const [pathCurveQ, setPathCurveQ] = useState(20);
  const [pathCurveHgt, setPathCurveHgt] = useState(300);
  const [airplaneDirX, setAirplaneDirX] = useState(40);
  const [airplaneDirY, setAirplaneDirY] = useState(270);
  const [moveMultiplyReturn, setMoveMultiplyReturn] = useState(1);

  const [gameConst, setGameConst] = useState({
    gameNumLot: 1,
    isGamePlaying: false,
    gameMultiplyReturn: 1,
    gameEntryPosition: false,
    gameEntryAmount: 10,
    gameMultipleEarnings: 10,
    gameEndDateTime: "",
  })

  const [winDialogConst, setWinDialogConst] = useState({
    winDialogShow: false,
    constPeriodId: "123",
    constWinAmount: 0,
  })

  const [pageConst, setGameData] = useState({
    pageTitle: "Aviator",
    gameMatchId: "",
    gameCode: "AVIATOR",
    gameFolderName: "aviator",
    isControlEnable: true,
    gameSelectedColor: "",
    requestAccountData: true,
    requestMaxDurationData: true,
    isLoadingShow: false,
    bettingDialogShow: "false",
    bettingDialogTitle: "Join Green",
    bettingDialogTheme: "dlg-thm-green",
    ruleDialogShow: false,
    toastDialogShow: false,
    toastMessage: "",
    tabActiveReord: "everyoneorder",
    gameWinDetails: [],
    gameRecordsList: [],
    mygameRecordsList: [],
    everyOnesOrderList: [],
  });

  const handleVisibilityChange = useCallback(() => {
    let isVisible = document.visibilityState;

    if (isVisible === 'visible') {
      getGameData();
    }
  }, []);

  const onChangeLotSize = (data) => {
    let updatedLotData = Number(data);
    let updatedAmountData = updatedLotData * 10;

    if (updatedLotData >= 1) {
      setGameConst(previousState => {
        return { ...previousState, gameNumLot: updatedLotData }
      });

      setGameConst(previousState => {
        return { ...previousState, gameEntryAmount: updatedAmountData }
      });
    } else {
      setGameConst(previousState => {
        return { ...previousState, gameNumLot: 0 }
      });

      setGameConst(previousState => {
        return { ...previousState, gameEntryAmount: 10 }
      });
    }
  }

  const updateLotSize = (data) => {
    let updatedLotData = gameConst.gameNumLot + Number(data);
    let updatedAmountData = updatedLotData * 10;

    if (updatedLotData >= 1 && updatedLotData < 200) {
      setGameConst(previousState => {
        return { ...previousState, gameNumLot: updatedLotData }
      });

      setGameConst(previousState => {
        return { ...previousState, gameEntryAmount: updatedAmountData }
      });
    }
  }

  const confirmBetEntry = () => {
    const fecthApiData = async (url) => {

      try {
        const res = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Route': 'route-bet-aviator',
            'AuthToken': getStorage("secret"),
          }
        });

        const data = await res.json();
        updateLoadingStatus(false);

        if (data.status_code === "success") {
          setEntryOption("none");
          setStorage("recentaviatorbet", data.match_period_id);
          setStorage("recentaviatorbetamount", gameConst.gameEntryAmount);
          setStorage("balance", data.account_balance);

          setGameConst(previousState => {
            return { ...previousState, gameNumLot: 1 }
          });

          setGameConst(previousState => {
            return { ...previousState, gameEntryAmount: 10 }
          });

          setGameConst(previousState => {
            return { ...previousState, gameMultiplyReturn: 1 }
          });
        } else if (data.status_code === "betting_timeout_error") {
          showToast("Oops! Betting timeout. Please try again!");
        } else {
          showToast("Something went wrong! Please try again!");
        }


      } catch (error) {
        updateLoadingStatus(false);
      }
    };

    if (remainingSec - gamePlayTime <= gamewaitingTime) {
      updateLoadingStatus(true);
      fecthApiData(API_ACCESS_URL + "?USER_ID=" + getStorage("uid") + "&NUM_LOT=" + gameConst.gameNumLot);
    }
  }

  const confirmBetCashout = () => {
    const fecthApiData = async (url) => {

      try {
        const res = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Route': 'route-cashout-aviator',
            'AuthToken': getStorage("secret"),
          }
        });

        const data = await res.json();

        if (data.status_code === "success") {
          setEntryOption("none");
          setStorage("recentaviatorbet", "", 5);
          setStorage("balance", data.account_balance);

          setWinDialogConst(previousState => {
            return { ...previousState, constPeriodId: pageConst.gameMatchId }
          });

          setWinDialogConst(previousState => {
            return { ...previousState, constWinAmount: data.match_profit }
          });

          updateWinDialogState(true);
        }

      } catch (error) {
        updateLoadingStatus(false);
      }
    };

    fecthApiData(API_ACCESS_URL + "?USER_ID=" + getStorage("uid") + "&MATCH_ID=" + pageConst.gameMatchId + "&CASHOUT=" + moveMultiplyReturn);
  }

  const actionEntryExit = (data) => {

    if (getStorage("recentaviatorbet") === pageConst.gameMatchId && moveMultiplyReturn > 1) {
      confirmBetCashout();
    } else if ((gamePlayTime - remainingSec) >= (gamePlayTime - gamewaitingTime) && getStorage("recentaviatorbet") != Number(pageConst.gameMatchId) + 1) {
      confirmBetEntry();
    }

  }

  const updatePeriod = (data) => {
    setGameData(previousState => {
      return { ...previousState, gameMatchId: data[0].game_period_id }
    });
  }

  const updateMyBetRecords = (recentBetData) => {
    let tempData = [];
    const recentBetArr = recentBetData.split(',');

    tempData.push(
      <div key={32} className="row-view mg-t-15 sb-view">
        <span className='ft-sz-14'>{recentBetArr[1]}</span>
        <span className={`ft-sz-17 h-w-28 br-50 cl-white v-center ${getColourCodeVal(recentBetArr[2]) == "r" ? 'bg-red' : getColourCodeVal(recentBetArr[2]) == "g" ? 'bg-green' : getColourCodeVal(recentBetArr[2]) == "rv" ? 'bg-rv' : getColourCodeVal(recentBetArr[2]) == "gv" ? 'bg-gv' : 'bg-violet'}`}>{recentBetArr[2]}</span>
        <span className='ft-sz-15'>{recentBetArr[3] + ".00"}</span>
        <span className={`ft-sz-17 h-w-28 br-50 cl-white v-center`}></span>
        <span className={`w-65-p ft-sz-15 txt-a-end`} ></span>
      </div>);

    let finalData = [tempData].concat(pageConst.mygameRecordsList);

    setGameData(previousState => {
      return { ...previousState, mygameRecordsList: finalData }
    });
  }

  const updateWinDialogState = (data) => {
    setWinDialogConst(previousState => {
      return { ...previousState, winDialogShow: data }
    });
  }

  const updateReqAcntData = (data) => {
    setGameData(previousState => {
      return { ...previousState, requestAccountData: data }
    });
  }

  const updateReqMaxDuration = (data) => {
    setGameData(previousState => {
      return { ...previousState, requestMaxDurationData: data }
    });
  }

  const updateActiveTab = (data) => {
    setGameData(previousState => {
      return { ...previousState, tabActiveReord: data }
    });
  }

  const updateLoadingStatus = (data) => {
    setGameData(previousState => {
      return { ...previousState, isLoadingShow: data }
    });
  }

  const getDialogThemeClassName = (data) => {
    let returnVal = "dlg-thm-green";
    if (data == "r" || data == "rv") {
      returnVal = "dlg-thm-red";
    } else if (data == "v") {
      returnVal = "dlg-thm-violet";
    }

    return returnVal;
  }

  const updateBettingDialogState = (e, data, selected, title) => {

    if (selected != "") {
      setGameData(previousState => {
        return { ...previousState, gameSelectedColor: selected }
      });
    }

    if (e != null && e.target.className == "ps-fx h-100vh res-wth z-i--100 bg-l-black bt-dlg activeDialog") {
      setGameData(previousState => {
        return { ...previousState, bettingDialogShow: data }
      });
    } else if (data == "true" && pageConst.isControlEnable) {
      setGameData(previousState => {
        return { ...previousState, bettingDialogShow: data }
      });

      setGameData(previousState => {
        return { ...previousState, bettingDialogTitle: title }
      });

      setGameData(previousState => {
        return { ...previousState, bettingDialogTheme: getDialogThemeClassName(getColourCodeVal(selected)) }
      });

    } else if (data == "dismiss") {
      setGameData(previousState => {
        return { ...previousState, bettingDialogShow: "false" }
      });
    }
  }

  const topBarClickAction = (data) => {
    if (data == "multiBtn1") {

    } else {
      setGameData(previousState => {
        return { ...previousState, ruleDialogShow: true }
      });
    }
  }

  const updateGameRuleDialog = (e, data) => {
    if (e != null && e.target.className == "ps-fx h-100vh res-wth z-i--100 bg-l-black rul-dlg activeDialog") {
      setGameData(previousState => {
        return { ...previousState, ruleDialogShow: data }
      });
    } else if (data == "dismiss") {
      setGameData(previousState => {
        return { ...previousState, ruleDialogShow: false }
      });
    } else if (data == "true") {
      setGameData(previousState => {
        return { ...previousState, ruleDialogShow: true }
      });
    }
  }

  // toast component
  const onToastChange = (data, msg) => { };

  const showToast = (message, duration, delay, mode) => {
    toastDialogRef.current.openDialog(message, duration, delay, mode);
  };

  // callback: on bet complete
  const onBetCompleteListener = (status, message = "", extra = "") => {
    if (status == "failed") {
      showToast(message, 2500, 100);
    } else if (status == "success") {
      showToast(message, 2500, 100, 1);
    }
  };

  const getColourCodeVal = (data) => {
    let returnVal = "";

    if (data == "1" || data == "3" || data == "7" || data == "9") {
      returnVal = "g";
    } else if (data == "2" || data == "4" || data == "6" || data == "8") {
      returnVal = "r";
    } else if (data == "0") {
      returnVal = "rv";
    } else if (data == "5") {
      returnVal = "gv";
    } else if (data == "red") {
      returnVal = "r";
    } else if (data == "green") {
      returnVal = "g";
    } else if (data == "violet") {
      returnVal = "v";
    }

    return returnVal;
  }

  const updateGameRecords = (data) => {
    let tempData = [];

    for (let i = 0; i < data.length; i++) {
      tempData.push(
        <div key={i} className="pd-5 v-center br-7 cl-white bg-blue">
          {Number(data[i]['match_result']).toFixed(2)}x
        </div>)
    };

    setGameData(previousState => {
      return { ...previousState, gameRecordsList: tempData }
    });

  }

  const updateEveryOnesOrder = (data) => {
    setGameData(previousState => {
      return { ...previousState, everyOnesOrderList: data }
    });
  }

  const getFixedDecimalVal = (data) => {
    return Number(data).toFixed();
  }

  const updateMyGameRecords = (data) => {
    let tempData = [];

    if (data.length > 0 && data[0]['m_status'] == "wait") {
      setStorage("recentaviatorbet", data[0]['m_period_id'], 5);
    }

    for (let i = 0; i < data.length; i++) {

      let tempMatchReslt = "";

      if (data[i]['m_status'] == "profit") {
        if (Number(data[i]['m_profit']) >= 1000000) {
          tempMatchReslt = "+Rp" + getFixedDecimalVal(Number(data[i]['m_profit']) / 1000) + "K";
        } else if (Number(data[i]['m_profit']) >= 100000) {
          tempMatchReslt = "+Rp" + getFixedDecimalVal(Number(data[i]['m_profit']) / 1000) + "K";
        } else if (Number(data[i]['m_profit']) >= 10000) {
          tempMatchReslt = "+Rp" + getFixedDecimalVal(Number(data[i]['m_profit']) / 1000) + "K";
        } else {
          tempMatchReslt = "+Rp" + Number(data[i]['m_profit']);
        }
      } else if (data[i]['m_status'] == "loss") {
        if (Number(data[i]['m_cost']) >= 1000000) {
          tempMatchReslt = "-Rp" + getFixedDecimalVal(Number(data[i]['m_cost']) / 1000) + "K";
        } else if (Number(data[i]['m_cost']) >= 100000) {
          tempMatchReslt = "-Rp" + getFixedDecimalVal(Number(data[i]['m_cost']) / 1000) + "K";
        } else if (Number(data[i]['m_cost']) >= 10000) {
          tempMatchReslt = "-Rp" + getFixedDecimalVal(Number(data[i]['m_cost']) / 1000) + "K";
        } else {
          tempMatchReslt = "-Rp" + Number(data[i]['m_cost']);
        }
      }

      tempData.push(
        <div key={i} className="row-view mg-t-15 sb-view">
          <span className='ft-sz-14 cl-white w-50-p'>{data[i].m_period_id}</span>
          <span className='ft-sz-15 cl-white w-65-p v-center'>{data[i]['m_cost'] + ".00"}</span>
          <span className={`ft-sz-17 h-w-28 br-50 cl-white v-center`}>{Number(data[i]['m_result']).toFixed(2)}</span>
          <span className={`w-65-p ft-sz-15 w-50-p txt-a-end ${data[i]['m_status'] == "loss" ? 'cl-red' : 'cl-green'}`} >{tempMatchReslt}</span>
        </div>)
    };

    setGameData(previousState => {
      return { ...previousState, mygameRecordsList: tempData }
    });
  }

  const getRandBool = () => {
    let returnVal = false;

    let status = Math.round(Math.random())
    if (status == 1) {
      returnVal = true;
    }

    return returnVal;
  }

  const getRandomNum = (min, max, type) => {
    if (type == "multi") {
      return Math.round((Math.random() * (max - min) + min) / 10) * 10;
    } else {
      return Math.floor(Math.random() * (max - min) + min);
    }
  }

  const updateEveryOnesOrders = (periodId, everyoneorder) => {

    if (periodId != "" && (gamePlayTime - remainingSec) >= (gamePlayTime - gamewaitingTime)) {

      let tempData = [];

      if (getRandBool()) {

        let uniq_id = Math.random() * (99999 - 10000) + 10000;

        if (everyoneorder.length > 0) {
          tempData = everyoneorder;
        }

        let randBetId = getRandomNum(100, 999, '');
        let randBetAmnt = 10;
        let randBetResult = 1.20;

        // random bet amount
        randBetAmnt = getRandomNum(10, 200, 'multi');

        // random bet result
        var min = 2;
        var max = 5;
        randBetResult = Math.random() * (max - min) + min;

        if (getRandBool()) {

          if (getRandBool()) {
            randBetAmnt = getRandomNum(10, 2000, 'multi');

            // random bet result
            var min = 5;
            var max = 10;
            randBetResult = Math.random() * (max - min) + min;
          }

        }

        // make more complex
        uniq_id += randBetAmnt;

        // make more complex
        uniq_id += randBetResult;

        tempData.push(
          <div key={uniq_id} className="row-view mg-t-15 sb-view">
            <span className='ft-sz-14 cl-white'>***{randBetId}</span>
            <span className={`ft-sz-14 h-w-28 cl-white br-5 v-center pd-0-30 ${randBetResult > 1.8 ? 'bg-green' : 'bg-blue'}`}>{randBetResult.toFixed(2)}</span>
            <span className={`ft-sz-14 cl-white w-65-p v-center`}>Rp{randBetAmnt}</span>
            <span className="ft-sz-14 w-50-p cl-green">Rp{(randBetAmnt * randBetResult).toFixed(2)}</span>
          </div>)

        updateEveryOnesOrder(tempData.reverse());
      }

    }
  }

  const getCurrentDateTime = (type) => {
    var date = new Date();
    if (type == 1) {
      return date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear() + " " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
    } else {
      return date.toLocaleString('default', { month: 'long' }) + " " + date.getDate() + ',' + date.getFullYear() + " " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
    }

  }

  const getSecondsLeft = (dt1, dt2) => {
    var t1 = new Date(dt1);
    var t2 = new Date(dt2);
    var dif = t1.getTime() - t2.getTime();

    return Math.abs(dif / 1000);
  }

  const stringToBool = (data) => {
    if (data == "true") {
      return true;
    } else {
      return false;
    }
  }

  function getGameData() {
    updateLoadingStatus(true);

    const fecthApiData = async (url) => {

      try {
        const res = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Route': 'route-setup-aviator',
            'AuthToken': getStorage("secret"),
          }
        });

        const data = await res.json();

        updateLoadingStatus(false);

        if (data.status_code == "success") {
          updatePeriod(data.gamedata);
          setStorage("balance", data.account_balance);
          setCount(data.gamedata[0].game_remain_seconds);
          setGamePlayTime(data.gamedata[0].game_play_time);
          setWaitingTime(data.gamedata[0].game_waiting_time);
          setWaitingForPlayers(stringToBool(data.gamedata[0].game_waiting_for_players));

          setGameConst(previousState => {
            return { ...previousState, gameEndDateTime: data.modified_time }
          });

          if (data.gamedata[0].game_playing == "true") {
            setGamePlaying(true);

            setMaxDistance(parseFloat(data.gamedata[0].game_max_distance));

            setMoveMultiplyReturn(data.gamedata[0].game_multiply);

            if (data.gamedata[0].game_multiply > 1.2) {
              setPathMoved();
            } else {
              restartGame();
            }

          } else {
            setGamePlaying(false);
          }


          updateGameRecords(data.matchrecords);
          updateMyGameRecords(data.mymatchrecords);

        } else {
          showToast("Something went wrong! Please try again or login!");
        }

      } catch (error) {
        updateLoadingStatus(false);
      }
    };

    fecthApiData(API_ACCESS_URL + "?USER_ID=" + getStorage("uid") + "&TIME=" + getCurrentDateTime(1) + "&PROJECT=" + pageConst.gameCode);
  }

  function setUpTimer(data) {

    if (data == "true") {
      updateReqAcntData(true);
    }

    if (pageConst.requestAccountData) {
      updateReqAcntData(false);
      getGameData();
    }
  }

  function restartGame() {

    setPathCurveHgt(300);
    setAirplaneDirY(270);

    setPathCurveQ(20);
    setPathCurve1(300);

    // x-axis move
    setPathCurve2(50);
    setAirplaneDirX(40);

    setMoveMultiplyReturn(1);

    setGameConst(previousState => {
      return { ...previousState, gameMultipleEarnings: 10 }
    });
  }

  function showWaitingForPlayers() {
    setWaitingForPlayers(true);
  }

  function setPathMoved() {
    setPathCurveHgt(99);
    setAirplaneDirY(69);

    setPathCurveQ(290);
    setPathCurve1(307);

    // x-axis move
    setPathCurve2(320);
    setAirplaneDirX(310);
  }

  useEffect(() => {

    document.addEventListener("visibilitychange", handleVisibilityChange);
    setUpTimer("false");

    const intervalMiliseconds = setInterval(() => {

      if (gameConst.gameEndDateTime != "") {

        let secLeft = getSecondsLeft(getCurrentDateTime(2), gameConst.gameEndDateTime);
        let newEveryOneOrder = false;

        if (pageConst.gameMatchId != "") {
          if (isGamePlaying && getStorage("recentaviatorbet") == Number(pageConst.gameMatchId) && moveMultiplyReturn > 1 && isEntryOptionAvailable != "hide") {
            setEntryOption("hide");
          } else if (getStorage("balance") >= 0 && gamePlayTime - remainingSec >= gamePlayTime - gamewaitingTime && isEntryOptionAvailable != "show" && getStorage("recentaviatorbet") != Number(pageConst.gameMatchId) + 1) {
            setEntryOption("show");
          } else if (moveMultiplyReturn <= 1 && gamePlayTime - remainingSec < gamePlayTime - gamewaitingTime && isEntryOptionAvailable != "none") {
            setEntryOption("none");
          }
        }

        if (remainingSec != secLeft) {
          newEveryOneOrder = true;
          setCount(secLeft);
        }

        if (remainingSec <= 0) {
          setUpTimer("true");

          setGameConst(previousState => {
            return { ...previousState, gameEndDateTime: "" }
          });

          updateEveryOnesOrder([]);
        } else if (newEveryOneOrder) {
          updateEveryOnesOrders(pageConst.gameMatchId, pageConst.everyOnesOrderList);
        }

      }

      if (isGamePlaying && !isWaitingForPlayers && planeMaxDistance > 0) {

        if (moveMultiplyReturn < planeMaxDistance) {
          // showing number of x times move
          setMoveMultiplyReturn(moveMultiplyReturn + 0.01);

          if (getStorage("recentaviatorbet") == pageConst.gameMatchId) {
            setGameConst(previousState => {
              return { ...previousState, gameMultiplyReturn: gameConst.gameMultiplyReturn + 0.01 }
            });

            let returnVal = Number(getStorage("recentaviatorbetamount")) * gameConst.gameMultiplyReturn;

            setGameConst(previousState => {
              return { ...previousState, gameMultipleEarnings: returnVal }
            });
          }
        }

        if (moveMultiplyReturn >= planeMaxDistance) {

          // disappear suddenly from screen
          setAirplaneDirX(airplaneDirX + window.innerWidth);
          if (getStorage("recentaviatorbet")) {
            setStorage("recentaviatorbet", "", 5);
          }

          if (isGamePlaying) {
            setGamePlaying(false);
          }

          setMoveMultiplyReturn(1);
          setTimeout(showWaitingForPlayers, 1200);
          setTimeout(restartGame, 5000);
        } else if (window.innerWidth >= 550 && pathCurve2 > 380) {
          if (pathCurveHgt > 100) {
            setPathCurveHgt(pathCurveHgt - 2);
            setAirplaneDirY(airplaneDirY - 2);
          }
        } else if (pathCurve2 > window.innerWidth - 100) {
          if (pathCurveHgt > 100) {
            setPathCurveHgt(pathCurveHgt - 2);
            setAirplaneDirY(airplaneDirY - 2);
          }
        } else {
          setPathCurveHgt(pathCurveHgt - 1.5);
          setAirplaneDirY(airplaneDirY - 1.5);

          setPathCurveQ(pathCurveQ + 3);
          setPathCurve1(pathCurve1 + 0.08);

          // x-axis move
          setPathCurve2(pathCurve2 + 3);
          setAirplaneDirX(airplaneDirX + 3);
        }

      }

    }, 100);

    //Clearing the interval
    return () => {
      // clearInterval(interval);
      clearInterval(intervalMiliseconds);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    }

  }, [pageConst.gameMatchId, gamePlayTime, gamewaitingTime, gameConst.gameEndDateTime, isWaitingForPlayers, planeMaxDistance, remainingSec, pathCurve1, pathCurve2, pathCurveHgt, moveMultiplyReturn, gameConst.gameMultiplyReturn, isGamePlaying, isEntryOptionAvailable]);


  return (
    <div className="v-center bg-black">
      <div className="h-100vh pr-v res-wth ovf-scrl-y hide-sb bg-black">
        <TopBar intentData={pageConst} multiBtn={true} multiBtn1="" multiBtn2="Rule" updateState={topBarClickAction} />
        <LoadingDialog intentData={pageConst} />
        <WinDialog intentData={winDialogConst} updateState={updateWinDialogState} />
        <ToastDialog onToastChange={onToastChange} ref={toastDialogRef} />
        <RuleDialog intentData={pageConst} updateState={updateGameRuleDialog} />

        <div className="game-manage-view col-view mg-t-45">

          <div className="col-view bg-black">

            <div className='g-v-6 pd-5-15 w-100 mg-t-15 z-i-100 bg-black'>
              {pageConst.gameRecordsList}
              <Link className='pd-5 v-center br-7 bg-white' to={"/myorders"}>
                <img className='h-w-20' src={require('../Aviator/icons/2.png')} alt="icon" />
              </Link>
            </div>

            <div className='pr-v h-340-p w-100 ovf-hidden'>
              <img className='ps-ab ps-lf--600 ps-btm--600 h-1200-w-1200 spin-anim-5s' src={require('../Aviator/icons/1.png')} alt="icon" />

              <div className={`ps-ab h-340-p col-view v-center z-i-110 bg-black ${!isWaitingForPlayers ? 'hide-v' : ''}`} style={{ top: '50%', width: '100%', transform: 'translateY(-50%)' }}>
                <div className='airplane-v flew-anim'></div>

                <div className='cl-white ft-sz-20 ft-wgt-700 mg-t-15'>{(gamePlayTime - remainingSec) >= (gamePlayTime - gamewaitingTime) ? 'WAITING FOR NEXT ROUND' : 'WAITING FOR PLAYERS'}</div>

                <div className={`txt-a-center cl-white ft-sz-32 ft-wgt-700 mg-t-15 ${(gamePlayTime - remainingSec) < (gamePlayTime - gamewaitingTime) ? 'hide-v' : ''}`} style={{ width: '130px' }}>{(gamePlayTime - remainingSec) >= (gamePlayTime - gamewaitingTime) ? remainingSec : '0'}s</div>
              </div>

              <div className={`ps-ab txt-a-center cl-white ft-wgt-700 z-i-100 ${Number(moveMultiplyReturn <= 1) ? 'hide-v' : ''}`} style={{ top: '20%', left: '50%', width: '130px', transform: 'translateX(-50%)', fontSize: '60px' }}>{moveMultiplyReturn.toFixed(2)}x</div>

              <div className='airplane-v ps-ab trn-05 flew-anim' style={{ left: airplaneDirX, top: airplaneDirY }}></div>

              <svg width="100%" height="300" className={`trn-05 ${!isGamePlaying ? 'hide-v' : ''}`}><path className='trn-05' d={`M40,300 Q${pathCurveQ},${pathCurve1} ${pathCurve2},${pathCurveHgt}`} fill="none" stroke="#F90443" strokeWidth="4"></path><defs><mask id="mask1"><path className='trn-05' d={`M40,300 L${pathCurve2},300 L${pathCurve2},${pathCurveHgt}`} fill="#fff" stroke="green" strokeWidth="2"></path><path className='trn-05' d={`M40,300 Q${pathCurveQ},${pathCurve1} ${pathCurve2},${pathCurveHgt}`} fill="#000" stroke="green" strokeWidth="2"></path></mask></defs><path className='trn-05' d={`M40,300 L${pathCurve2},300 L${pathCurve2},${pathCurveHgt}`} fill="rgba(249, 4, 67, .4)" mask="url(#mask1)"></path></svg>

              <div className='ps-ab ps-lf-40 ps-btm tns-t-50 h-40-p w-100 z-i-100 a-center bg-black br-t-grey' >
                <div className='speedMovespot driftAnimRtL'></div>
                <div className='speedMovespot driftAnimRtL' style={{ animationDelay: '1s' }}></div>
                <div className='speedMovespot driftAnimRtL' style={{ animationDelay: '2s' }}></div>
                <div className='speedMovespot driftAnimRtL' style={{ animationDelay: '3s' }}></div>
                <div className='speedMovespot driftAnimRtL' style={{ animationDelay: '4s' }}></div>
                <div className='speedMovespot driftAnimRtL' style={{ animationDelay: '5s' }}></div>
                <div className='speedMovespot driftAnimRtL' style={{ animationDelay: '6s' }}></div>
                <div className='speedMovespot driftAnimRtL' style={{ animationDelay: '7s' }}></div>
                <div className='speedMovespot driftAnimRtL' style={{ animationDelay: '8s' }}></div>
                <div className='speedMovespot driftAnimRtL' style={{ animationDelay: '9s' }}></div>
                <div className='speedMovespot driftAnimRtL' style={{ animationDelay: '10s' }}></div>
                <div className='speedMovespot driftAnimRtL' style={{ animationDelay: '11s' }}></div>
                <div className='speedMovespot driftAnimRtL' style={{ animationDelay: '12s' }}></div>
                <div className='speedMovespot driftAnimRtL' style={{ animationDelay: '13s' }}></div>
                <div className='speedMovespot driftAnimRtL' style={{ animationDelay: '14s' }}></div>
                <div className='speedMovespot driftAnimRtL' style={{ animationDelay: '15s' }}></div>
                <div className='speedMovespot driftAnimRtL' style={{ animationDelay: '16s' }}></div>
                <div className='speedMovespot driftAnimRtL' style={{ animationDelay: '17s' }}></div>
                <div className='speedMovespot driftAnimRtL' style={{ animationDelay: '18s' }}></div>
                <div className='speedMovespot driftAnimRtL' style={{ animationDelay: '19s' }}></div>
                <div className='speedMovespot driftAnimRtL' style={{ animationDelay: '20s' }}></div>
              </div>

              <div className='ps-ab ps-btm-40 ps-lf tns-l-50 h-380-p w-40-p z-i-100 bg-black br-r-grey'>
                <div className='speedMovespot driftAnimTtB'></div>
                <div className='speedMovespot driftAnimTtB' style={{ animationDelay: '1s' }}></div>
                <div className='speedMovespot driftAnimTtB' style={{ animationDelay: '2s' }}></div>
                <div className='speedMovespot driftAnimTtB' style={{ animationDelay: '3s' }}></div>
                <div className='speedMovespot driftAnimTtB' style={{ animationDelay: '4s' }}></div>
                <div className='speedMovespot driftAnimTtB' style={{ animationDelay: '5s' }}></div>
                <div className='speedMovespot driftAnimTtB' style={{ animationDelay: '6s' }}></div>
                <div className='speedMovespot driftAnimTtB' style={{ animationDelay: '7s' }}></div>
                <div className='speedMovespot driftAnimTtB' style={{ animationDelay: '8s' }}></div>
                <div className='speedMovespot driftAnimTtB' style={{ animationDelay: '9s' }}></div>
                <div className='speedMovespot driftAnimTtB' style={{ animationDelay: '10s' }}></div>
                <div className='speedMovespot driftAnimTtB' style={{ animationDelay: '11s' }}></div>
                <div className='speedMovespot driftAnimTtB' style={{ animationDelay: '12s' }}></div>
                <div className='speedMovespot driftAnimTtB' style={{ animationDelay: '13s' }}></div>
                <div className='speedMovespot driftAnimTtB' style={{ animationDelay: '14s' }}></div>
                <div className='speedMovespot driftAnimTtB' style={{ animationDelay: '15s' }}></div>
                <div className='speedMovespot driftAnimTtB' style={{ animationDelay: '16s' }}></div>
                <div className='speedMovespot driftAnimTtB' style={{ animationDelay: '17s' }}></div>
                <div className='speedMovespot driftAnimTtB' style={{ animationDelay: '18s' }}></div>
                <div className='speedMovespot driftAnimTtB' style={{ animationDelay: '19s' }}></div>
                <div className='speedMovespot driftAnimTtB' style={{ animationDelay: '20s' }}></div>
              </div>
            </div>

          </div>

          <div className="col-view pd-10-20">
            <div className='cl-white'>Total Bet Amount: <span className='mg-l-5 cl-red ft-sz-18'>Rp{gameConst.gameEntryAmount}</span></div>
            <div className='cl-white'>Balance: <span className='mg-l-5 cl-green ft-sz-18'>Rp{Number(getStorage("balance")).toLocaleString()}</span></div>

            <div className='g-v-2 w-100 mg-t-10'>
              <div className='row-view sb-view bg-l-white pd-10 br-5'>
                <div className='h-w-45 v-center ft-sz-25 cl-white br-a-l-blue br-50' onClick={() => updateLotSize('-1')}>-</div>
                {/* <div className='w-20-p ft-sz-25 mg-l-15 cl-white'>{gameConst.gameNumLot}</div> */}
                <input type="number" className='txt-a-center ft-sz-20 h-w-45 cl-white outline-none br-none bg-transparent' autoComplete="new-password" onChange={e => onChangeLotSize(e.target.value)} value={gameConst.gameNumLot <= 0 ? '' : gameConst.gameNumLot}></input>
                <div className='h-w-45 v-center ft-sz-25 cl-white br-a-l-blue br-50' onClick={() => updateLotSize('+1')}>+</div>
              </div>

              <div className={`col-view v-center cl-white br-10 ${isEntryOptionAvailable == 'show' ? 'ft-sz-25 bg-drk-green bx-shdw-green' : isEntryOptionAvailable == 'hide' ? 'ft-sz-20 bg-red bx-shdw-red' : 'ft-sz-25 bg-drk-green bx-shdw-green greys-100'}`} onClick={() => actionEntryExit()}>{getStorage("recentaviatorbet") == pageConst.gameMatchId ? (moveMultiplyReturn > 1 ? 'CASHOUT' : 'START') : 'START'}<label className='ft-sz-16 mg-t-5'>{getStorage("recentaviatorbet") == pageConst.gameMatchId ? (moveMultiplyReturn > 1 ? gameConst.gameMultipleEarnings.toFixed(2) : '') : ''}</label></div>

            </div>

          </div>

          <div className="col-view br-right-t br-left-t mg-t-30 mg-b-15">

            <div className="tab-slct-v w-100">
              <div className={`v-center tab-in-v pd-10 ft-sz-18 w-100 cl-white bg-l-white ${pageConst.tabActiveReord == "everyoneorder" ? 'active' : ''}`} onClick={() => updateActiveTab('everyoneorder')}>Everyone's Order</div>
              <div className={`v-center tab-in-v pd-10 ft-sz-18 w-100 cl-white bg-l-white ${pageConst.tabActiveReord == "myorder" ? 'active' : ''}`} onClick={() => updateActiveTab('myorder')}>My Order</div>
            </div>

            <div className={`col-view min-h pd-18 ${pageConst.tabActiveReord != "everyoneorder" ? 'hide-v' : ''}`}>
              <div className="row-view cl-white sb-view">
                <span>User</span>
                <span>Result</span>
                <span>Point</span>
                <span>Bonus</span>
              </div>

              <div className="col-view mg-t-15 fd-up-anim">
                {pageConst.everyOnesOrderList}
              </div>
            </div>

            <div className={`col-view min-h pd-18 ${pageConst.tabActiveReord != "myorder" ? 'hide-v' : ''}`}>
              <div className="row-view cl-white sb-view">
                <span className='ft-sz-14'>Period</span>
                <span className='ft-sz-14'>Amount</span>
                <span className='ft-sz-14'>Point</span>
                <span className='ft-sz-14'>Bonus</span>
              </div>

              <div className="col-view mg-t-15">
                {pageConst.mygameRecordsList}
              </div>

              <Link className="w-100 v-center pd-10 mg-t-15 br-15 bg-grey txt-deco-n" to={"/myorders"}>More</Link>
            </div>

          </div>

        </div>
      </div>
    </div>
  );
}

export default Aviator;