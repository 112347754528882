import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TopBar from '../other-components/TopBar';
import LoadingDialog from '../dialogs/LoadingDialog/dialog';
import '../../MainStyle.css';
import { API_ACCESS_URL } from '../modals/Constants';
import { getStorage } from '../modals/Storage';
import general from "../css/General.module.css";
import styles from "../css/Screens.module.css";

function TodaysReports() {
  const navigate = useNavigate();
  const [showLoading, setShowLoading] = useState(false);

  const [pageConst, setConstants] = useState({
    pageTitle: "Agent Reports",
    totalIncome: 0,
    totalInvited: 0,
    todayInvited: 0,
    todayActive: 0,
    teamBalance: 0,
    teamTotalBet: 0,
    teamBetAmount: 0,
    totalWithdrawls: 0,
    totalWithdrawlAmount: 0,
    totalRecharges: 0,
    totalRechargeAmount: 0,
    totalBettingCommission: 0,
    totalRechargeCommission: 0,
    recordList: [],
  });

  const topBarClickAction = (data) => {
    if (data == "multiBtn1") {
      navigate('/withdraw', { replace: false });
    }
  }

  const updateLoadingStatus = (data) => {
    setShowLoading(data);
  }

  const updateRecordList = (data) => {
    let tempData = [];

    for (let i = 0; i < data.length; i++) {
      tempData.push(
        <div key={i} className="row-view sb-view pd-10-15 bg-extm-l-white br-a-l-blue br-5 mg-t-15">
          <div className='row-view'>
            <img className='h-w-32' src={require('../icons/othericons/1.png')} />

            <div className='col-view mg-l-20'>
              <span className='ft-sz-17'>{data[i]['t_title']}</span>
              <span className='ft-sz-12 mg-t-5'>{data[i]['t_time_stamp']}</span>
            </div>
          </div>
          <span className='ft-sz-17 ft-wgt-b cl-green'>Rp{data[i]['t_amount']}</span>
        </div>)
    };

    setConstants(previousState => {
      return { ...previousState, recordList: tempData }
    });
  }

  function getRecords() {
    const fecthApiData = async (url, formData) => {
      try {
        const res = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Route': 'route-agent-reports',
            'AuthToken': getStorage("secret"),
          },
          body: JSON.stringify(formData),
        });
        const data = await res.json();
        console.log(data.total_income);
        updateLoadingStatus(false);

        if (data.status_code == "success") {

          setConstants(previousState => {
            return { ...previousState, totalIncome: data.total_income }
          });

          setConstants(previousState => {
            return { ...previousState, totalInvited: data.total_invited }
          });


          setConstants(previousState => {
            return { ...previousState, teamBalance: data.team_balance }
          });

          setConstants(previousState => {
            return { ...previousState, totalBettingCommission: data.total_bet_income }
          });

          setConstants(previousState => {
            return { ...previousState, todayActive: data.today_active }
          });

          setConstants(previousState => {
            return { ...previousState, totalRechargeCommission: data.total_recharge_income }
          });

          setConstants(previousState => {
            return { ...previousState, totalRechargeAmount: data.total_recharge }
          });

          setConstants(previousState => {
            return { ...previousState, totalWithdrawlAmount: data.total_withdraw }
          });

          setConstants(previousState => {
            return { ...previousState, todayInvited: data.today_invited }
          });


        }

      } catch (error) {
        updateLoadingStatus(false);
      }
    };

    updateLoadingStatus(true);
    const formData = {
      USER_ID: getStorage("uid"),
    };
    fecthApiData(API_ACCESS_URL, formData);
  }

  useEffect(() => {
    getRecords();
  }, []);

  return (
    <div className={[general.viewCenter, general.appBackground].join(" ")}>
      <div
        className={[
          general.height100vh,
          general.respWidth,
          general.overflowScrollY,
          general.appContentsBackground,
          general.hideScrollBar,
        ].join(" ")}
      >
        <TopBar intentData={pageConst} multiBtn={true} multiBtn1="" multiBtn2="" updateState={topBarClickAction} />
        <LoadingDialog intentData={showLoading} />

        <div className="col-view mg-t-45">

          <div className="col-view br-right-t br-left-t mg-b-15">

            <div className='col-view pd-5-15'>
              <div className={[styles.agentReportsView]}>

                <div className={[styles.itemView]}>
                  <p>Rp {pageConst.totalIncome}</p>
                  <p>Total Agent Income</p>
                </div>

                <div className={[styles.itemView]}>
                  <p>Rp {pageConst.totalBettingCommission}</p>
                  <p>Betting Commission</p>
                </div>

                <div className={[styles.itemView]}>
                  <p>Rp {pageConst.totalRechargeCommission}</p>
                  <p>Recharge Commission</p>
                </div>

                <div className={[styles.itemView]}>
                  <p>{pageConst.totalInvited}</p>
                  <p>Total Users</p>
                </div>

                <div className={[styles.itemView]}>
                  <p>{pageConst.todayActive}</p>
                  <p>Today Active</p>
                </div>

                <div className={[styles.itemView]}>
                  <p>{pageConst.todayInvited}</p>
                  <p>Today Registered</p>
                </div>

                <div className={[styles.itemView]}>
                  <p>Rp {pageConst.teamBalance}</p>
                  <p>Team Balance</p>
                </div>

                <div className={[styles.itemView]}>
                  <p>Rp {pageConst.totalRechargeAmount}</p>
                  <p>Recharge Amount</p>
                </div>

                <div className={[styles.itemView]}>
                  <p>Rp {pageConst.totalWithdrawlAmount}</p>
                  <p>Withdrawl Amount</p>
                </div>

              </div>
            </div>

          </div>

        </div>
      </div>
    </div>
  );
}

export default TodaysReports;