import React, {useEffect,useState} from 'react'
import '../../MainStyle.css';

const winDialog = ({intentData,updateState}) => {

    return (
        <div className={`ps-fx h-100vh res-wth z-i--100 bg-l-black v-center ${intentData.winDialogShow ? intentData.constWinDialogState!='' ? 'activeDialog' : '' : ''}`} onClick={()=>{updateState(false)}}>
            <div className='ps-rl w-85'>
                <img className="ps-ab ps-lf-50 ps-tp-50 t-form-xy w-300-p" src={intentData.constWinDialogState=='win' ? require('../icons/win_dialog.png') : require('../icons/lose_dialog.png')} />
                
                <div className="ps-ab ps-lf-50 ps-tp-50 t-form-xy w-350-p col-view v-center" >

                    <span className='ps-ab ps-lf-50 t-form-x col-view cl-white ft-sz-25' style={{top: '-60px'}}>{intentData.constWinDialogState=='win' ? 'Congratulations' : 'Sorry'}</span>

                    <div className='ps-ab ps-lf-50 t-form-x col-view cl-drk-red' style={{top: '35px'}}>
                      <span className={`txt-a-center ${intentData.constWinDialogState=='win' ? 'ft-sz-15' : 'ft-sz-25'}`}>{intentData.constWinDialogState=='win' ? 'Bonus' : 'Lose'}</span>
                      <span className={`txt-a-center ft-wgt-500 ft-sz-22 ${intentData.constWinDialogState!='win' ? 'hide-v' : ''}`}>Rp{Number(intentData.constWinAmount).toLocaleString()}</span>
                    </div>

                    <span className='ps-ab ps-lf-50 t-form-x col-view cl-black ft-sz-12' style={{top: '92px'}}>Period: {intentData.constPeriodId}</span>

                    <div className='ps-ab ps-lf-50 t-form-x h-w-32 br-50 col-view v-center cl-white bg-l-white-2' style={{top: '250px'}}>
                      <span>X</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default winDialog;