import React, { useCallback, useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom';
import TopBar from '../../other-components/TopBar';
import BettingDialog from '../../dialogs/BettingDialog';
import LoadingDialog from '../../dialogs/LoadingDialog/dialog';
import ToastDialog from '../../dialogs/ToastDialog/toast';
import WinDialog from '../../dialogs/WinDialog';
import RuleDialog from '../../dialogs/AndarBaharRuleDialog';
import { setStorage, getStorage } from '../../modals/Storage';
import { API_ACCESS_URL } from '../../modals/Constants';

import styles from "../AndarBahar/Game.module.css";

function AndarBahar() {
  const toastDialogRef = useRef(null);
  const [remainingSec, setCount] = useState(60);
  const [disableTime, setDisableTime] = useState(30);
  const [cardShiftAnimDuration, setAnimationDuration] = useState(0);
  const [showLoading, setShowLoading] = useState(false);

  const [gameConst, setGameConst] = useState({
    moveCardShuffle: false,
    moveSelectCard: false,
    moveSelectCardNum: 1,
    movePrevSelectedCard: 0,
    cardShift1: false,
    cardShift2: false,
    cardShift3: false,
    cardShift4: false,
    cardShift5: false,
    cardShift6: false,
    cardShift7: false,
    cardShift8: false,
    cardShift9: false,
    cardShift1Right: false,
    cardShift2Right: false,
    cardShift3Right: false,
    cardShift4Right: false,
    cardShift5Right: false,
    cardShift6Right: false,
    cardShift7Right: false,
    cardShift8Right: false,
    cardShift9Right: false,
    cardShift1Delay: '0',
    cardShift2Delay: '0',
    cardShift3Delay: '0',
    cardShift4Delay: '0',
    cardShift5Delay: '0',
    cardShift6Delay: '0',
    cardShift7Delay: '0',
    cardShift8Delay: '0',
    cardShift9Delay: '0',
    cardShift1Zindex: '90',
    cardShift2Zindex: '90',
    cardShift3Zindex: '90',
    cardShift4Zindex: '90',
    cardShift5Zindex: '90',
    cardShift6Zindex: '90',
    cardShift7Zindex: '90',
    cardShift8Zindex: '90',
    cardShift9Zindex: '90',
  })

  const [pageConst, setGameData] = useState({
    pageTitle: "Andar Bahar",
    gamePeriodId: "",
    gameCode: "ANDARBAHAR",
    remainingSec1: 0,
    remainingSec2: 0,
    remainingMin1: 0,
    remainingMin2: 0,
    isControlEnable: true,
    requestAccountData: true,
    ruleDialogShow: false,
    winDialogShow: false,
    toastDialogShow: false,
    toastTimeAvail: 5,
    toastMessage: "",
    tabActiveReord: "everyoneorder",
    gameWinDetails: [],
    gameRecordsList: [],
    mygameRecordsList: [],
    everyOnesOrderList: [],

  });

  const [bettingDialogConst, setBettingDialogConst] = useState({
    bettingDialogShow: false,
    bettingDialogTitle: "Join Green",
    bettingDialogTheme: "dlg-thm-green",
    gameBetRoutePath: "route-bet-common",
    gameSelectedBet: "",
    gameSelectedMultiply: "1",
  });

  const [winDialogConst, setWinDialogConst] = useState({
    winDialogShow: false,
    constPeriodId: "",
    constWinAmount: 0,
    constWinDialogResult: "",
    constWinDialogState: "",
  });

  const handleVisibilityChange = useCallback(() => {
    let isVisible = document.visibilityState === 'visible';
    if (isVisible) {
      getGameData(false);
    }
  }, []);

  const updatePeriod = (data) => {
    setGameData(previousState => {
      return { ...previousState, gamePeriodId: data[0].game_period_id }
    });

    setGameConst(previousState => {
      return { ...previousState, moveSelectCardNum: data[0].game_choose_val }
    });

    setGameConst(previousState => {
      return { ...previousState, moveSelectCard: true }
    });
  }

  const updateWinDialogState = (data) => {
    setWinDialogConst(previousState => {
      return { ...previousState, winDialogShow: data }
    });
  }

  const updateGameControl = (data) => {
    setGameData(previousState => {
      return { ...previousState, isControlEnable: data }
    });
  }

  const updateReqAcntData = (data) => {
    setGameData(previousState => {
      return { ...previousState, requestAccountData: data }
    });
  }

  const updateRemainSec = (data, val) => {
    if (data === "1") {
      setGameData(previousState => {
        return { ...previousState, remainingSec1: val }
      });
    } else {
      setGameData(previousState => {
        return { ...previousState, remainingSec2: val }
      });
    }
  }

  const updateRemainMin = (data, val) => {
    if (data === "1") {
      setGameData(previousState => {
        return { ...previousState, remainingMin1: val }
      });
    } else {
      setGameData(previousState => {
        return { ...previousState, remainingMin2: val }
      });
    }
  }

  const updateActiveTab = (data) => {
    setGameData(previousState => {
      return { ...previousState, tabActiveReord: data }
    });
  }

  const updateLoadingStatus = (data) => {
    setShowLoading(data);
  }

  const getDialogThemeClassName = (data) => {
    let returnVal = "dlg-thm-orange";
    if (data === "A") {
      returnVal = "dlg-thm-violet";
    } else if (data === "B") {
      returnVal = "dlg-thm-red";
    }

    return returnVal;
  }

  const updateBettingDialogState = (e, data, selected, title) => {
    if (selected !== "") {
      setBettingDialogConst(previousState => {
        return { ...previousState, gameSelectedBet: selected }
      });
    }

    if (e != null && e.target.className == "ps-fx h-100vh res-wth z-i--100 bg-l-black bt-dlg activeDialog") {
      setBettingDialogConst(previousState => {
        return { ...previousState, bettingDialogShow: data }
      });
    } else if (data == true && pageConst.isControlEnable) {
      setBettingDialogConst(previousState => {
        return { ...previousState, bettingDialogShow: data }
      });

      setBettingDialogConst(previousState => {
        return { ...previousState, bettingDialogTitle: title }
      });

      setBettingDialogConst(previousState => {
        return { ...previousState, bettingDialogTheme: getDialogThemeClassName(selected) }
      });

    } else if (data == "dismiss") {
      setBettingDialogConst(previousState => {
        return { ...previousState, bettingDialogShow: false }
      });
    }
  }

  const topBarClickAction = (data) => {
    if (data == "multiBtn1") {

    } else {
      setGameData(previousState => {
        return { ...previousState, ruleDialogShow: true }
      });
    }
  }

  const updateGameRuleDialog = (e, data) => {
    if (e != null && e.target.className == "ps-fx h-100vh res-wth z-i--100 bg-l-black rul-dlg activeDialog") {
      setGameData(previousState => {
        return { ...previousState, ruleDialogShow: data }
      });
    } else if (data == "dismiss") {
      setGameData(previousState => {
        return { ...previousState, ruleDialogShow: false }
      });
    } else if (data == "true") {
      setGameData(previousState => {
        return { ...previousState, ruleDialogShow: true }
      });
    }
  }

  // toast component
  const onToastChange = (data, msg) => { };

  const showToast = (message, duration, delay, mode) => {
    toastDialogRef.current.openDialog(message, duration, delay, mode);
  };

  // callback: on bet complete
  const onBetCompleteListener = (status, message = "", extra = "") => {
    if (status == "failed") {
      showToast(message, 2500, 100);
    } else if (status == "success") {
      showToast(message, 2500, 100, 1);
    }
  };

  const getCapitalLetter = (data) => {
    let returnVal = "";

    if (data == "red") {
      returnVal = "R";
    } else if (data == "green") {
      returnVal = "G";
    } else if (data == "violet") {
      returnVal = "V";
    } else {
      returnVal = data;
    }

    return returnVal;
  }

  const getBetBgClass = (data) => {
    let returnVal = "";

    if (data == "A") {
      returnVal = "bg-blue";
    } else if (data == "B") {
      returnVal = "bg-red";
    } else if (data == "T") {
      returnVal = "bg-orange";
    }

    return returnVal;
  }

  const updateGameRecords = (data, game_choose_val, resetStatus) => {
    let tempData = [], currentPeriod = "", numNeedToRemove = "";
    for (let i = data.length - 1; i > -1; i--) {
      if (i != 29) {

        if (i == 0) {
          cardShiftRecords(data[i]['match_result'], game_choose_val, resetStatus)
          currentPeriod = Number(data[i]['period_id'].slice(-3)) + 1;
          if (currentPeriod < 10) {
            currentPeriod = "00" + currentPeriod;
          } else if (currentPeriod < 100) {
            currentPeriod = "0" + currentPeriod;
          }
          numNeedToRemove = 10 - Number(data[i]['period_id'].slice(-1));
        }

        tempData.push(
          <div key={i} className="col-view a-center">
            <div className={`ft-sz-17 h-w-28 br-50 cl-white v-center ${getBetBgClass(data[i]['match_result'])}`}>{data[i]['match_result']}</div>
            <span className="ft-sz-14">{data[i]['period_id'].slice(-3)}</span>
          </div>)
      }
    };


    if (data.length > 20) {
      tempData.splice(0, numNeedToRemove);
    }

    tempData.push(
      <div key={31} className="col-view a-center">
        <div className='ft-sz-17 h-w-28 br-50 cl-white v-center bg-yellow'>?</div>
        <span className="ft-sz-14">{currentPeriod}</span>
      </div>)

    setGameData(previousState => {
      return { ...previousState, gameRecordsList: tempData }
    });

  }

  const updateEveryOnesOrder = (data) => {
    setGameData(previousState => {
      return { ...previousState, everyOnesOrderList: data }
    });
  }

  const getFixedDecimalVal = (data) => {
    return Number(data).toFixed();
  }

  function shuffle(array) {
    let currentIndex = array.length, randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex > 0) {

      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }

    return array;
  }

  const setCardShiftStatus = (data, direction, delayTime, zIndex) => {
    if (data == 1) {
      setGameConst(previousState => {
        return { ...previousState, cardShift1: 1 }
      });

      setGameConst(previousState => {
        return { ...previousState, cardShift1Right: direction }
      });

      setGameConst(previousState => {
        return { ...previousState, cardShift1Delay: delayTime }
      });

      setGameConst(previousState => {
        return { ...previousState, cardShift1Zindex: zIndex }
      });
    } else if (data == 2) {
      setGameConst(previousState => {
        return { ...previousState, cardShift2: 2 }
      });

      setGameConst(previousState => {
        return { ...previousState, cardShift2Right: direction }
      });

      setGameConst(previousState => {
        return { ...previousState, cardShift2Delay: delayTime }
      });

      setGameConst(previousState => {
        return { ...previousState, cardShift2Zindex: zIndex }
      });
    } else if (data == 3) {
      setGameConst(previousState => {
        return { ...previousState, cardShift3: 3 }
      });

      setGameConst(previousState => {
        return { ...previousState, cardShift3Right: direction }
      });

      setGameConst(previousState => {
        return { ...previousState, cardShift3Delay: delayTime }
      });

      setGameConst(previousState => {
        return { ...previousState, cardShift3Zindex: zIndex }
      });
    } else if (data == 4) {
      setGameConst(previousState => {
        return { ...previousState, cardShift4: 4 }
      });

      setGameConst(previousState => {
        return { ...previousState, cardShift4Right: direction }
      });

      setGameConst(previousState => {
        return { ...previousState, cardShift4Delay: delayTime }
      });

      setGameConst(previousState => {
        return { ...previousState, cardShift4Zindex: zIndex }
      });
    } else if (data == 5) {
      setGameConst(previousState => {
        return { ...previousState, cardShift5: 5 }
      });

      setGameConst(previousState => {
        return { ...previousState, cardShift5Right: direction }
      });

      setGameConst(previousState => {
        return { ...previousState, cardShift5Delay: delayTime }
      });

      setGameConst(previousState => {
        return { ...previousState, cardShift5Zindex: zIndex }
      });
    } else if (data == 6) {
      setGameConst(previousState => {
        return { ...previousState, cardShift6: 6 }
      });

      setGameConst(previousState => {
        return { ...previousState, cardShift6Right: direction }
      });

      setGameConst(previousState => {
        return { ...previousState, cardShift6Delay: delayTime }
      });

      setGameConst(previousState => {
        return { ...previousState, cardShift6Zindex: zIndex }
      });
    } else if (data == 7) {
      setGameConst(previousState => {
        return { ...previousState, cardShift7: 7 }
      });

      setGameConst(previousState => {
        return { ...previousState, cardShift7Right: direction }
      });

      setGameConst(previousState => {
        return { ...previousState, cardShift7Delay: delayTime }
      });

      setGameConst(previousState => {
        return { ...previousState, cardShift7Zindex: zIndex }
      });
    } else if (data == 8) {
      setGameConst(previousState => {
        return { ...previousState, cardShift8: 8 }
      });

      setGameConst(previousState => {
        return { ...previousState, cardShift8Right: direction }
      });

      setGameConst(previousState => {
        return { ...previousState, cardShift8Delay: delayTime }
      });

      setGameConst(previousState => {
        return { ...previousState, cardShift8Zindex: zIndex }
      });
    } else if (data == 9) {
      setGameConst(previousState => {
        return { ...previousState, cardShift9: 9 }
      });

      setGameConst(previousState => {
        return { ...previousState, cardShift9Right: direction }
      });

      setGameConst(previousState => {
        return { ...previousState, cardShift9Delay: delayTime }
      });

      setGameConst(previousState => {
        return { ...previousState, cardShift9Zindex: zIndex }
      });
    }
  }

  const getDelayTimeClass = (data) => {
    let returnVal = "";

    if (data == 1) {
      returnVal = "trn-d-0p5";
    } else if (data == 2) {
      returnVal = "trn-d-1";
    } else if (data == 3) {
      returnVal = "trn-d-1p5";
    } else if (data == 4) {
      returnVal = "trn-d-2";
    } else if (data == 5) {
      returnVal = "trn-d-2p5";
    } else if (data == 6) {
      returnVal = "trn-d-3";
    } else if (data == 7) {
      returnVal = "trn-d-3p5";
    } else if (data == 8) {
      returnVal = "trn-d-4";
    } else if (data == 9) {
      returnVal = "trn-d-4p5";
    }

    return returnVal;
  }

  const cardShiftRecords = (match_result, game_choose_val, resetStatus) => {

    if (resetStatus) {

      let randNum = getRandomNum(3, 6, '');
      var arr = [1, 2, 3, 4, 5, 6, 7, 8, 9];
      var shuffledArr = shuffle(arr);

      if (match_result == "A") {
        let countIndex = 1;
        let countZIndex = 90;
        let animDirectionRight = true;

        for (let i = 0; i < randNum; i++) {
          let selectedRandPos = shuffledArr[i];

          if (i == randNum - 1) {
            setCardShiftStatus(gameConst.movePrevSelectedCard, false, getDelayTimeClass(countIndex), countZIndex);
            break;
          }

          setCardShiftStatus(selectedRandPos, animDirectionRight, getDelayTimeClass(countIndex), countZIndex);
          countIndex++;
          countZIndex++;

          if (animDirectionRight) {
            animDirectionRight = false;
          } else {
            animDirectionRight = true;
          }
        }

        setAnimationDuration(Math.ceil(countIndex * 0.5 + 1));
      } else if (match_result == "B") {
        let countIndex = 1;
        let countZIndex = 90;
        let animDirectionRight = false;

        for (let i = 0; i < randNum; i++) {
          let selectedRandPos = shuffledArr[i];

          if (i == randNum - 1) {
            setCardShiftStatus(gameConst.movePrevSelectedCard, true, getDelayTimeClass(countIndex), countZIndex);
            break;
          }

          setCardShiftStatus(selectedRandPos, animDirectionRight, getDelayTimeClass(countIndex), countZIndex);
          countIndex++;
          countZIndex++;

          if (animDirectionRight) {
            animDirectionRight = false;
          } else {
            animDirectionRight = true;
          }
        }

        setAnimationDuration(Math.ceil(countIndex * 0.5 + 1));
      } else {
        setAnimationDuration(2);
      }

      updateAnimationState(true);

    } else if (gameConst.movePrevSelectedCard == 0) {
      setGameConst(previousState => {
        return { ...previousState, movePrevSelectedCard: game_choose_val }
      });
    }

  }

  const updateMyGameRecords = (data) => {
    let tempData = [];

    for (let i = 0; i < data.length; i++) {

      if (i == 0 && getStorage("recentandarbaharbet")) {
        const recentBetArr = getStorage("recentandarbaharbet").split(',');

        if (data[0]['m_period_id'] == recentBetArr[1] && data[0]['m_project'] == recentBetArr[0] && data[0]['m_status'] == "profit") {

          setWinDialogConst(previousState => {
            return { ...previousState, constPeriodId: data[0]['m_period_id'] }
          });

          setWinDialogConst(previousState => {
            return { ...previousState, constWinAmount: data[0]['m_profit'] }
          });

          setStorage("recentandarbaharbet", "");
          updateWinDialogState(true);
        }
      }

      let tempMatchReslt = "";
      if (data[i]['m_status'] == "profit") {
        if (Number(data[i]['m_profit']) >= 1000000) {
          tempMatchReslt = "+Rp" + getFixedDecimalVal(Number(data[i]['m_profit']) / 1000) + "K";
        } else if (Number(data[i]['m_profit']) >= 100000) {
          tempMatchReslt = "+Rp" + getFixedDecimalVal(Number(data[i]['m_profit']) / 1000) + "K";
        } else if (Number(data[i]['m_profit']) >= 10000) {
          tempMatchReslt = "+Rp" + getFixedDecimalVal(Number(data[i]['m_profit']) / 1000) + "K";
        } else {
          tempMatchReslt = "+Rp" + Number(data[i]['m_profit']);
        }
      } else if (data[i]['m_status'] == "loss") {
        if (Number(data[i]['m_cost']) >= 1000000) {
          tempMatchReslt = "-Rp" + getFixedDecimalVal(Number(data[i]['m_cost']) / 1000) + "K";
        } else if (Number(data[i]['m_cost']) >= 100000) {
          tempMatchReslt = "-Rp" + getFixedDecimalVal(Number(data[i]['m_cost']) / 1000) + "K";
        } else if (Number(data[i]['m_cost']) >= 10000) {
          tempMatchReslt = "-Rp" + getFixedDecimalVal(Number(data[i]['m_cost']) / 1000) + "K";
        } else {
          tempMatchReslt = "-Rp" + Number(data[i]['m_cost']);
        }
      }

      tempData.push(
        <div key={i} className="row-view mg-t-15 sb-view">
          <span className='ft-sz-14 w-50-p'>{data[i].m_period_id}</span>
          <span className={`ft-sz-17 h-w-28 br-50 cl-white v-center ${getBetBgClass(data[i]['m_color'])}`}>{getCapitalLetter(data[i]['m_color'])}</span>
          <span className={`ft-sz-17 h-w-28 br-50 cl-white v-center ${getBetBgClass(data[i]['m_result'])}`}>{getCapitalLetter(data[i]['m_result'])}</span>
          <span className={`w-65-p ft-sz-15 txt-a-end ${data[i]['m_status'] == "loss" ? 'cl-red' : 'cl-green'}`} >{tempMatchReslt}</span>
        </div>)
    };

    setGameData(previousState => {
      return { ...previousState, mygameRecordsList: tempData }
    });
  }

  const getRandBool = () => {
    let returnVal = false;

    let status = Math.round(Math.random())
    if (status == 1) {
      returnVal = true;
    }

    return returnVal;
  }

  const getRandomNum = (min, max, type) => {
    if (type == "multi") {
      return Math.round((Math.random() * (max - min) + min) / 10) * 10;
    } else {
      return Math.floor(Math.random() * (max - min) + min);
    }
  }

  const recentBetDetails = (invested_amount) => {
    setStorage("recentandarbaharbet", pageConst.gamePeriodId);

    let tempData = [];

    tempData.push(
      <div key={60} className="row-view mg-t-15 sb-view">
        <span className='ft-sz-14 w-50-p'>{pageConst.gamePeriodId}</span>
        <span className={`ft-sz-17 h-w-28 br-50 cl-white v-center ${getBetBgClass(bettingDialogConst.gameSelectedBet)}`}>{getCapitalLetter(bettingDialogConst.gameSelectedBet)}</span>
        <span className={`ft-sz-17 h-w-28 br-50 cl-white v-center`}></span>
        <span className={`w-65-p ft-sz-15 txt-a-end`} ></span>
      </div>)

    let finalArr = [tempData].concat(pageConst.mygameRecordsList);

    setGameData(previousState => {
      return { ...previousState, mygameRecordsList: finalArr }
    });
  }

  const updateEveryOnesOrders = (periodId, remainSec, controlEnabled, everyoneorder) => {
    if (periodId != "") {

      let tempData = [];

      if (controlEnabled == true && getRandBool()) {

        if (everyoneorder.length > 0) {
          tempData = everyoneorder;
        }

        let randBetId = getRandomNum(100, 999, '');
        let randBetAmnt = 10;

        if (getRandBool()) {
          randBetAmnt = getRandomNum(10, 300, 'multi');
        } else {
          randBetAmnt = getRandomNum(10, 2000, 'multi');
        }

        let randBetNum = "";

        if (getRandBool()) {
          randBetNum = "A"
        } else {
          randBetNum = "B";
        }

        tempData.push(
          <div key={remainSec} className="row-view mg-t-15 sb-view">
            <span className='ft-sz-14'>{periodId}</span>
            <span className='ft-sz-14'>***{randBetId}</span>
            <span className={`ft-sz-17 h-w-28 br-50 cl-white v-center ${getBetBgClass(randBetNum)}`}>{getCapitalLetter(randBetNum)}</span>
            <span className="ft-sz-14 w-50-p">Rp{randBetAmnt}</span>
          </div>)

        updateEveryOnesOrder(tempData.reverse());
      }

    }
  }

  function getGameData(resetStatus) {
    updateLoadingStatus(true);

    const fecthApiData = async (url) => {

      try {
        const res = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Route': 'route-setup-andarbahar',
            'AuthToken': getStorage("secret"),
          }
        });

        const data = await res.json();

        updateLoadingStatus(false);

        if (data.status_code == "success") {
          updatePeriod(data.gamedata);
          setCount(data.gamedata[0].game_remain_seconds);
          setDisableTime(data.gamedata[0].game_disable_time);
          updateGameRecords(data.matchrecords, data.gamedata[0].game_choose_val, resetStatus);
          updateMyGameRecords(data.mymatchrecords);
        } else {
          showToast("Something went wrong! Please try again or login!");
        }

      } catch (error) {
        updateLoadingStatus(false);
      }
    };

    fecthApiData(API_ACCESS_URL + "?USER_ID=" + getStorage("uid") + "&PROJECT=" + pageConst.gameCode);
  }

  function setUpTimer(resetStatus) {
    if (pageConst.requestAccountData == true) {
      updateReqAcntData(false);
      getGameData(resetStatus);
    }
  }

  const updateAnimationState = (data) => {
    if (data == false) {

      for (let i = 1; i < 10; i++) {
        setCardShiftStatus(i, false, '0', '90');
      }

      setGameConst(previousState => {
        return { ...previousState, movePrevSelectedCard: gameConst.moveSelectCardNum }
      });

    }

    setGameConst(previousState => {
      return { ...previousState, moveCardShuffle: data }
    });
  }

  useEffect(() => {

    document.addEventListener("visibilitychange", handleVisibilityChange);
    setUpTimer(false);

    const interval = setInterval(() => {
      if (remainingSec >= 0) {
        setCount(remainingSec - 1);

        if (remainingSec <= 0) {
          getGameData(true);
          setCount(60);
          updateEveryOnesOrder([]);
        } else {

          function splitIntoArray(num) {
            return Array.from(String(num), Number);
          }

          const minutes = Math.floor(remainingSec / 60);
          const seconds = remainingSec % 60;

          if (minutes < 10) {
            updateRemainMin("1", 0);
            updateRemainMin("2", minutes);
          } else {
            var numArr = splitIntoArray(minutes);
            updateRemainMin("1", numArr[0]);
            updateRemainMin("2", numArr[1]);
          }

          if (seconds < 10) {
            updateRemainSec("1", 0);
            updateRemainSec("2", seconds);
          } else {
            var numArr = splitIntoArray(seconds);
            updateRemainSec("1", numArr[0]);
            updateRemainSec("2", numArr[1]);
          }

          if (cardShiftAnimDuration > 0 && gameConst.moveCardShuffle) {
            setAnimationDuration(cardShiftAnimDuration - 1);

            if (cardShiftAnimDuration == 1) {
              updateAnimationState(false);
            }
          }

          if (remainingSec < disableTime && pageConst.isControlEnable == true) {
            updateGameControl(false);
          } else if (remainingSec > disableTime && pageConst.isControlEnable == false) {
            updateGameControl(true);
          }

          updateEveryOnesOrders(pageConst.gamePeriodId, remainingSec, pageConst.isControlEnable, pageConst.everyOnesOrderList);
        }
      }
    }, 1000);

    //Clearing the interval
    return () => {
      clearInterval(interval);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    }

  }, [remainingSec, gameConst.moveCardShuffle]);

  return (
    <div className="v-center">
      <div className="h-100vh pr-v res-wth ovf-scrl-y hide-sb bg-grey">
        <TopBar intentData={pageConst} multiBtn={true} multiBtn1="" multiBtn2="Rule" updateState={topBarClickAction} />
        <LoadingDialog intentData={showLoading} />
        <WinDialog intentData={winDialogConst} updateState={updateWinDialogState} />
        <ToastDialog onToastChange={onToastChange} ref={toastDialogRef} />
        <RuleDialog intentData={pageConst} updateState={updateGameRuleDialog} />
        <BettingDialog
          gameCode={pageConst.gameCode}
          dialogData={bettingDialogConst}
          updateState={updateBettingDialogState}
          onBetCompleteListener={onBetCompleteListener}
          recentBetDetails={recentBetDetails} />

        <div className="game-manage-view col-view mg-t-45">

          <div className="col-view bg-drk-blue">

            <div className="row-view sb-view pd-15">
              <div className="col-view">
                <span className="cl-white m-bottom-n">Period</span>
                <span className="f-size-max cl-white ltr-s ft-wgt-600">{pageConst.gamePeriodId}</span>
              </div>

              <div className="col-view a-right">
                <span className="cl-white m-bottom-n">Count Down</span>
                <div className={[styles.countDownTimerView]}>
                  <span>{gameConst.moveCardShuffle != true ? pageConst.remainingMin1 : '0'}</span>
                  <span>{gameConst.moveCardShuffle != true ? pageConst.remainingMin2 : '0'}</span>
                  :
                  <span>{gameConst.moveCardShuffle != true ? pageConst.remainingSec1 : '0'}</span>
                  <span>{gameConst.moveCardShuffle != true ? pageConst.remainingSec2 : '0'}</span>
                </div>
              </div>
            </div>

            <div className={`col-view a-center mg-t-10 pd-15`}>

              <div className="h-128-w-96 bg-puff br-10">
                <img className={`h-128-w-96 trn-05 trn-d-1s ${gameConst.moveSelectCard == true ? '' : 'move-top-anim'}`} src={require(`../AndarBahar/icons/${gameConst.movePrevSelectedCard > 0 ? gameConst.movePrevSelectedCard : '1'}.png`)} alt="icon" />
              </div>

              <div className="v-center w-100 mg-t-20">
                <div className="h-128-w-96 br-10 br-a-l-blue">
                  <img className='h-128-w-96 br-10' src={require('../AndarBahar/icons/andar_bg.png')} alt="icon" />
                </div>

                <div className="pr-v h-115-w-164 v-center mg-l-5 br-10">
                  <img className="ps-ab h-115-w-164 z-i-100 br-10" src={require('../AndarBahar/icons/ab_bg.png')} alt="icon" />
                  <img className={`ps-ab h-128-w-96 trn-05 z-i-${gameConst.cardShift1Zindex} ${gameConst.cardShift1Delay != '0' ? gameConst.cardShift1Delay : ''} ${gameConst.moveCardShuffle == true ? (gameConst.cardShift1 ? (gameConst.cardShift1Right ? 'move-right-anim' : 'move-left-anim') : '') : ''}`} src={require('../AndarBahar/icons/1.png')} alt="icon" />
                  <img className={`ps-ab h-128-w-96 trn-05 z-i-${gameConst.cardShift2Zindex} ${gameConst.cardShift2Delay != '0' ? gameConst.cardShift2Delay : ''} ${gameConst.moveCardShuffle == true ? (gameConst.cardShift2 ? (gameConst.cardShift2Right ? 'move-right-anim' : 'move-left-anim') : '') : ''}`} src={require(`../AndarBahar/icons/2.png`)} alt="icon" />
                  <img className={`ps-ab h-128-w-96 trn-05 z-i-${gameConst.cardShift3Zindex} ${gameConst.cardShift3Delay != '0' ? gameConst.cardShift3Delay : ''} ${gameConst.moveCardShuffle == true ? (gameConst.cardShift3 ? (gameConst.cardShift3Right ? 'move-right-anim' : 'move-left-anim') : '') : ''}`} src={require(`../AndarBahar/icons/3.png`)} alt="icon" />
                  <img className={`ps-ab h-128-w-96 trn-05 z-i-${gameConst.cardShift4Zindex} ${gameConst.cardShift4Delay != '0' ? gameConst.cardShift4Delay : ''} ${gameConst.moveCardShuffle == true ? (gameConst.cardShift4 ? (gameConst.cardShift4Right ? 'move-right-anim' : 'move-left-anim') : '') : ''}`} src={require(`../AndarBahar/icons/4.png`)} alt="icon" />
                  <img className={`ps-ab h-128-w-96 trn-05 z-i-${gameConst.cardShift5Zindex} ${gameConst.cardShift5Delay != '0' ? gameConst.cardShift5Delay : ''} ${gameConst.moveCardShuffle == true ? (gameConst.cardShift5 ? (gameConst.cardShift5Right ? 'move-right-anim' : 'move-left-anim') : '') : ''}`} src={require(`../AndarBahar/icons/5.png`)} alt="icon" />
                  <img className={`ps-ab h-128-w-96 trn-05 z-i-${gameConst.cardShift6Zindex} ${gameConst.cardShift6Delay != '0' ? gameConst.cardShift6Delay : ''} ${gameConst.moveCardShuffle == true ? (gameConst.cardShift6 ? (gameConst.cardShift6Right ? 'move-right-anim' : 'move-left-anim') : '') : ''}`} src={require(`../AndarBahar/icons/6.png`)} alt="icon" />
                  <img className={`ps-ab h-128-w-96 trn-05 z-i-${gameConst.cardShift7Zindex} ${gameConst.cardShift7Delay != '0' ? gameConst.cardShift7Delay : ''} ${gameConst.moveCardShuffle == true ? (gameConst.cardShift7 ? (gameConst.cardShift7Right ? 'move-right-anim' : 'move-left-anim') : '') : ''}`} src={require(`../AndarBahar/icons/7.png`)} alt="icon" />
                  <img className={`ps-ab h-128-w-96 trn-05 z-i-${gameConst.cardShift8Zindex} ${gameConst.cardShift8Delay != '0' ? gameConst.cardShift8Delay : ''} ${gameConst.moveCardShuffle == true ? (gameConst.cardShift8 ? (gameConst.cardShift8Right ? 'move-right-anim' : 'move-left-anim') : '') : ''}`} src={require(`../AndarBahar/icons/8.png`)} alt="icon" />
                  <img className={`ps-ab h-128-w-96 trn-05 z-i-${gameConst.cardShift9Zindex} ${gameConst.cardShift9Delay != '0' ? gameConst.cardShift9Delay : ''} ${gameConst.moveCardShuffle == true ? (gameConst.cardShift9 ? (gameConst.cardShift9Right ? 'move-right-anim' : 'move-left-anim') : '') : ''}`} src={require(`../AndarBahar/icons/9.png`)} alt="icon" />
                </div>

                <div className="h-128-w-96 mg-l-5 br-10 br-a-l-blue">
                  <img className='h-128-w-96 br-10' src={require('../AndarBahar/icons/bahar_bg.png')} alt="icon" />
                </div>
              </div>

            </div>

            <div className={`row-view mg-t-10 pd-15 ${pageConst.isControlEnable ? '' : 'disable-view'}`}>

              <div className="col-view w-100 a-center">
                <div className="v-center w-100 h-55-p pd-15-0 cl-white br-10 bg-blue" onClick={() => updateBettingDialogState(null, true, 'A', 'Andar')}>
                  <span>Andar</span>
                </div>
                <span className='mg-t-5 cl-l-white'>1:2</span>
              </div>

              <div className="col-view w-100 a-center mg-l-10" onClick={() => updateBettingDialogState(null, true, 'T', 'Tie')}>
                <div className="v-center w-100 h-55-p pd-15-0 cl-white br-10 bg-orange">
                  <span>Tie</span>
                </div>
                <span className='mg-t-5 cl-l-white'>1:5</span>
              </div>

              <div className="col-view w-100 a-center mg-l-10" onClick={() => updateBettingDialogState(null, true, 'B', 'Bahar')}>
                <div className="v-center w-100 h-55-p pd-15-0 cl-white br-10 bg-red">
                  <span>Bahar</span>
                </div>
                <span className='mg-t-5 cl-l-white'>1:2</span>
              </div>

            </div>

          </div>

          <div className="col-view br-right-t br-left-t mg-t-25 bg-white">

            <div className="tab-slct-v w-100">
              <div className="v-center tab-in-v pd-10 ft-sz-18 ft-wgt-700 w-100 active">Record</div>
            </div>

            <div className="col-view pd-10">
              <div className="row-view sb-view">
                <span>Parity Records</span>
                <Link className="rc-btn txt-deco-n" to={"/gamerecords?GC=" + pageConst.gameCode}>more</Link>
              </div>

              <div className="g-v-10 mg-t-15">
                {pageConst.gameRecordsList}
              </div>
            </div>

          </div>

          <div className="col-view br-right-t br-left-t mg-t-25 mg-b-15 bg-white">

            <div className="tab-slct-v w-100">
              <div className={`v-center tab-in-v pd-10 ft-sz-18 w-100 ${pageConst.tabActiveReord == "everyoneorder" ? 'active' : ''}`} onClick={() => updateActiveTab('everyoneorder')}>Everyone's Order</div>
              <div className={`v-center tab-in-v pd-10 ft-sz-18 w-100 ${pageConst.tabActiveReord == "myorder" ? 'active' : ''}`} onClick={() => updateActiveTab('myorder')}>My Order</div>
            </div>

            <div className={`col-view min-h pd-18 ${pageConst.tabActiveReord != "everyoneorder" ? 'hide-v' : ''}`}>
              <div className="row-view sb-view">
                <span>Period</span>
                <span>User</span>
                <span>Select</span>
                <span>Point</span>
              </div>

              <div className="col-view mg-t-15 fd-up-anim">
                {pageConst.everyOnesOrderList}
              </div>
            </div>

            <div className={`col-view min-h pd-18 ${pageConst.tabActiveReord != "myorder" ? 'hide-v' : ''}`}>
              <div className="row-view sb-view">
                <span className='ft-sz-14'>Period</span>
                <span className='ft-sz-14 mg-l-30'>Select</span>
                <span className='ft-sz-14'>Result</span>
                <span className='ft-sz-14'>Amount</span>
              </div>

              <div className="col-view mg-t-15">
                {pageConst.mygameRecordsList}
              </div>

              <Link className="w-100 v-center pd-10 mg-t-15 br-15 bg-grey txt-deco-n" to={"/myorders"}>More</Link>
            </div>

          </div>

        </div>
      </div>
    </div>
  );
}

export default AndarBahar;