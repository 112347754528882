import React, { useEffect, useState } from "react";
import BottomNavbar from "../other-components/BottomNavbar/Navbar";
import LoadingDialog from "../dialogs/LoadingDialog";
import "../../MainStyle.css";
import { Link, useNavigate } from "react-router-dom";
import { getStorage } from "../modals/Storage";
import { openNewPage } from "../modals/Constants";

import styles from "../css/Screens.module.css";
import general from "../css/General.module.css";

function Deposit() {
  const navigate = useNavigate();
  const [pageConst, setConstants] = useState({
    pageTitle: "Deposit",
    isLoadingShow: false,
    inRechargeAmnt: "",
    recordList: [],
  });

  const topBarClickAction = (data) => {
    if (data == "multiBtn1") {
      navigate("/RechargeRecords", { replace: false });
    }
  };

  const onInputValChange = (source, data) => {
    console.log(data);

    if (source == "rechargeamount") {
      setConstants((previousState) => {
        return { ...previousState, inRechargeAmnt: data };
      });
    }
  };

  const rechargeNow = () => {
    if (
      getStorage("paymentURL") &&
      Number(pageConst.inRechargeAmnt) >= getStorage("minrecharge")
    ) {
      openNewPage(
        getStorage("paymentURL") +
          "?am=" +
          pageConst.inRechargeAmnt +
          "&crudentials=" +
          getStorage("uid")
      );
    }
  };

  const setAllRechargeOptions = () => {
    let tempData = [];
    const recentBetArr = getStorage("rechargeoptions").split(",");

    for (let i = 0; i < recentBetArr.length; i++) {
      tempData.push(
        <div
          key={i}
          className="pd-10-15 v-center br-5 ft-sz-16 bg-l-blue"
          onClick={() => onInputValChange("rechargeamount", recentBetArr[i])}
        >
          Rp{recentBetArr[i]}
        </div>
      );
    }

    setConstants((previousState) => {
      return { ...previousState, recordList: tempData };
    });
  };

  useEffect(() => {
    if (!getStorage("uid")) {
      navigate("/login", { replace: true });
    } else {
      setAllRechargeOptions();
    }
  }, []);

  return (
    <div className={[general.viewCenter, general.appBackground].join(" ")}>
      <div
        className={[
          general.height100vh,
          general.respWidth,
          general.overflowScrollY,
          general.appContentsBackground,
          general.hideScrollBar,
        ].join(" ")}
      >
        <LoadingDialog intentData={pageConst} />

        <div className="zoomAnimView">
          <div className="col-view">
            <div
              className={[styles.walletDetailView, general.bgCustomColor1].join(
                " "
              )}
            >
              <span className="cl-white ft-sz-23">Wallet</span>
              <img
                src={require("../icons/wallet_grey_icon.png")}
                className="h-w-40 mg-t-10"
                alt="icon"
              />

              <span className="cl-white ft-sz-28">
                Rp{Number(getStorage("balance")).toLocaleString()}
              </span>
              <span className="cl-white ft-sz-12">Total Balance</span>
            </div>

            <div className="w-100 pd-0-15 mg-t--40">
              <div className="w-100 col-view pd-10-15 br-10 bg-custom-lightblue bx-shadow-2">
                <div className="col-view">
                  <span className="ft-sz-16 ft-wgt-500 cl-l-grey">Amount</span>
                  <div className="row-view br-b-grey">
                    <span className="cl-l-grey ft-sz-20">Rp</span>
                    <input
                      type="number"
                      className="cutm-inp ft-sz-38 h-60-p inp-ph-l-color cl-white"
                      value={pageConst.inRechargeAmnt}
                      placeholder={`${getStorage("minrecharge")} ~ 70000`}
                      onChange={(e) =>
                        onInputValChange("rechargeamount", e.target.value)
                      }
                    ></input>
                  </div>
                </div>

                <div className="w-100 g-v-3 mg-t-25">
                  {pageConst.recordList}
                </div>

                <div
                  className={[
                    general.height45px,
                    general.width100,
                    general.viewCenter,
                    general.colorWhite,
                    general.fontS20Px,
                    general.mgT10px,
                    general.borderRadius,
                    general.bgCustomColor2,
                  ].join(" ")}
                  onClick={() => rechargeNow()}
                >
                  Deposit
                </div>

                <div className="row-view sb-view a-flex-start mg-t-20">
                  <Link
                    className="txt-deco-n col-view br-10"
                    to={"/withdraw"}
                  >
                    <div className="txt-a-center">
                      <img
                        src={require("../media/icons/withdrawl_icon.png")}
                        className="h-w-28"
                        alt="icon"
                      />
                    </div>
                    <span className="cl-white ft-sz-12 mg-t-5">
                      Withdraw
                    </span>
                  </Link>

                  <Link
                    className="txt-deco-n col-view br-10"
                    to={"/rechargerecords"}
                  >
                    <div className="txt-a-center">
                      <img
                        src={require("../media/icons/deposit_history_icon.png")}
                        className="h-w-28"
                        alt="icon"
                      />
                    </div>
                    <span className="cl-white txt-a-center ft-sz-12 mg-t-5">
                      Deposit<br></br>History
                    </span>
                  </Link>

                  <Link
                    className="txt-deco-n col-view br-10"
                    to={"/withdrawrecords"}
                  >
                    <div className="txt-a-center">
                      <img
                        src={require("../media/icons/withdrawl_history_icon.png")}
                        className="h-w-28"
                        alt="icon"
                      />
                    </div>
                    <span className="cl-white txt-a-center ft-sz-12 mg-t-5">
                      Withdraw<br></br>History
                    </span>
                  </Link>

                  <Link
                    className="txt-deco-n col-view br-10"
                    to={"/transactions"}
                  >
                    <div className="txt-a-center">
                      <img
                        src={require("../media/icons/transactions_icon.png")}
                        className="h-w-28"
                        alt="icon"
                      />
                    </div>
                    <span className="cl-white ft-sz-12 mg-t-5">
                      Transactions
                    </span>
                  </Link>
                </div>
              </div>

              <div className="mg-t-13em"></div>
            </div>
          </div>
        </div>

        <BottomNavbar activeBar="recharge" />
      </div>
    </div>
  );
}

export default Deposit;
